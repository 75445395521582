import { useScroll } from '@vueuse/core'
import { BlockModule } from './common'
import { computed, watch } from 'vue'

new BlockModule({
    selector: '.block-scroll-to-top',
    setupBlock(el) {
        const { arrivedState, y } = useScroll(window)

        const button = el.querySelector('a')

        if (!button) return

        const canShow = computed(() => {
            if (arrivedState.top || y.value < 400) return false

            return true
        })

        watch(canShow, (newVal) => {
            if (newVal) {
                button?.classList.remove('translate-y-18')
                button?.classList.add('pointer-events-auto')
            } else {
                button?.classList.add('translate-y-18')
                button?.classList.remove('pointer-events-auto')
            }
        })
    },
})
