import 'swiper/css'

import {
    computed,
    defineAsyncComponent,
    defineComponent,
    reactive,
    ref,
    shallowRef,
    toRef,
    watch,
} from 'vue'
import { BlockModule } from '../common'
import type { Swiper as SwiperClass } from 'swiper'
import { Navigation } from 'swiper/modules'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useGenericListingItemsAsync } from '@/datasources'
import { LazyImage } from '@/components/website/lazy-image'
import { DateLabelWithTooltip } from '@/components/website/dates'
import type { GenericListingItem } from '@/types'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

new BlockModule({
    selector: '.block-generic-listing',
    vueComponent: defineComponent({
        components: {
            LazyImage,
            DateLabelWithTooltip,
            BnmButton: defineAsyncComponent(() => import('@/components/website/button/button.vue')),

            SwiperVue: defineAsyncComponent(() => import('swiper/vue').then((x) => x.Swiper)),
            SwiperSlide: defineAsyncComponent(() =>
                import('swiper/vue').then((x) => x.SwiperSlide),
            ),
            ShadcnSelect: Select,
            SelectContent,
            SelectGroup,
            SelectItem,
            SelectValue,
            SelectTrigger,

            Pagination: defineAsyncComponent(
                () => import('@/components/ui/custom-pagination/pagination.vue'),
            ),

            ShadcnDialog: defineAsyncComponent(() => import('@/components/ui/dialog/Dialog.vue')),
            DialogContent: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogContent.vue'),
            ),
            DialogDescription: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogDescription.vue'),
            ),
            DialogTitle: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTitle.vue'),
            ),

            Drawer: defineAsyncComponent(() => import('@/components/ui/drawer/Drawer.vue')),
            DrawerClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue'),
            ),
            DrawerContent: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerContent.vue'),
            ),
            DrawerDescription: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerDescription.vue'),
            ),
            DrawerFooter: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerFooter.vue'),
            ),
            DrawerTitle: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerTitle.vue'),
            ),
            DrawerTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue'),
            ),
        },
        props: {
            listingId: { type: String, required: true },
            defaultSort: String,
        },
        setup(props) {
            let isFirstBoot = true
            const topRef = ref<HTMLElement>()
            const isBusy = ref(true)

            const breakpoints = useBreakpoints(breakpointsTailwind)
            const mdAndGreater = breakpoints.greater('md')

            const filters = reactive({
                tagIds: [] as string[],
                sorting: props.defaultSort ?? '0',
                pageNumber: 1,
                pageSize: 9,
                listingId: props.listingId,
            })

            const swiperInstance = shallowRef<SwiperClass>()

            const updateSwiper = () => {
                swiperInstance.value?.update()
            }

            const setSwiperInstance = (swiper: SwiperClass) => {
                swiperInstance.value = swiper
            }

            const onCategoryFilterChange = (tagId: string) => {
                const index = filters.tagIds.findIndex((x) => x === tagId)
                if (index !== -1) {
                    filters.tagIds.splice(index, 1)
                } else {
                    filters.tagIds.push(tagId)
                }
            }

            const genericListingAsync = useGenericListingItemsAsync(
                props.listingId,
                toRef(filters, 'tagIds'),
                toRef(filters, 'sorting'),
                toRef(filters, 'pageNumber'),
                toRef(filters, 'pageSize'),
                { evaluating: isBusy },
            )

            const getItemLink = (item: GenericListingItem) => {
                switch (item.itemContentType) {
                    case 'Youtube Embed':
                    case 'Video Upload':
                        return null
                    case 'File Upload':
                        return item.fileUpload
                    case 'Details Page':
                    default:
                        return item.ctaLink ? item.ctaLink : item.url
                }
            }

            watch(
                () => filters.tagIds.join(','),
                () => {
                    filters.pageNumber = 1
                },
            )

            watch(
                () => genericListingAsync.value?.currentPageNumber,
                () => {
                    if (isFirstBoot) {
                        isFirstBoot = false
                        return
                    }
                    topRef.value?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                },
            )

            const filterCount = computed(() => {
                return filters.tagIds.length
            })

            return {
                Navigation,
                updateSwiper,
                swiperInstance,
                filters,
                setSwiperInstance,
                onCategoryFilterChange,
                genericListingAsync,
                isBusy,
                getItemLink,
                topRef,
                mdAndGreater,
                filterCount,
            }
        },
    }),
    setupBlock() {
        import('../button/button.css')
        // @ts-ignore
        import('swiper/css/navigation')
    },
})
