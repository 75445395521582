export const isMediaLoaded = (imageOrArray: Element | Element[]) => {
    if (!imageOrArray) {
        return Promise.resolve()
    }
    imageOrArray = imageOrArray instanceof Element ? [imageOrArray] : Array.from(imageOrArray)
    return Promise.all(
        imageOrArray.map((image) => {
            return new Promise<void>((resolve) => {
                if (image instanceof HTMLImageElement && (image.complete || !image.offsetParent)) {
                    resolve()
                } else {
                    ;(image as HTMLElement).onload = () => resolve()
                }
            })
        }),
    )
}
