import { importGsap, importScrollTrigger } from '@/lib/gsap'

const gsap = importGsap()
const ScrollTrigger = importScrollTrigger()

export class MotionList extends HTMLElement {
    constructor(private _st: ScrollTrigger) {
        super()
    }

    connectedCallback() {
        const motionOK = !window.matchMedia('(prefers-reduced-motion: reduce)').matches
        if (!motionOK) return

        this.unload()

        this._st = ScrollTrigger.create({
            trigger: this,
            markers: this.debug,
            onEnter: async () => {
                this.load()
            },
            once: true,
            start: 'top bottom-=10%',
        })
    }

    disconnectedCallback() {
        this._st?.kill()
    }

    get items() {
        return this.children
    }

    get itemsToShow() {
        return this.querySelectorAll('.card:not([style])')
    }

    get delay() {
        return parseInt(this.dataset.animateDelay || '0') / 1000
    }

    get debug() {
        return !!this.dataset.debug
    }

    unload() {
        gsap.set(this.items, { y: 50, autoAlpha: 0 })
    }

    load() {
        gsap.to(this.items, { y: 0, autoAlpha: 1, stagger: 0.1, duration: 0.5, delay: this.delay })
    }

    reload() {
        gsap.to(this.items, { y: 50, autoAlpha: 1, stagger: 0.1, duration: 0.5 })
    }
}
