<template>
    <div class="flex flex-row gap-4" :class="{ [theme]: true }">

        <button @click.prevent="handleFacebookShare">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="text-rubyRed flex-shrink-0 hover:bg-rubyRed hover:text-white hover:rounded-full">
                <g clip-path="url(#clip0_8037_16307)">
                    <circle cx="16" cy="16" r="15.5" stroke="currentColor" />
                    <g clip-path="url(#clip1_8037_16307)">
                        <path
                            d="M12.5415 17.7913H14.2202V23.9993C14.2202 24.2753 14.4435 24.4993 14.7202 24.4993L17.3915 24.5C17.6682 24.5 17.8915 24.2753 17.8915 24V17.792H19.4828C19.7348 17.792 19.9475 17.604 19.9788 17.354L20.3108 14.6873C20.3482 14.39 20.1155 14.1253 19.8148 14.1253H17.8915C17.9668 12.478 17.6015 11.9907 18.6728 11.9907C19.3982 11.904 20.5422 12.2713 20.5422 11.4907V9.106C20.5422 8.856 20.3575 8.64467 20.1102 8.61067C19.9008 8.582 19.0735 8.5 18.0475 8.5C13.3742 8.5 14.3362 13.6813 14.2208 14.1247H12.5415C12.2655 14.1247 12.0415 14.3487 12.0415 14.6247V17.2913C12.0415 17.5673 12.2655 17.7913 12.5415 17.7913ZM13.0415 15.1253H14.7202C14.9962 15.1253 15.2202 14.9013 15.2202 14.6253V12.5247C15.2202 10.6027 16.2502 9.50067 18.0468 9.50067C18.6322 9.50067 19.1708 9.52933 19.5415 9.55867V10.9913C19.2735 11.128 16.8908 10.402 16.8908 12.7893V14.626C16.8908 14.902 17.1148 15.126 17.3908 15.126H19.2482L19.0402 16.7927H17.3908C17.1148 16.7927 16.8908 17.0167 16.8908 17.2927V23.5H15.2208V17.292C15.2208 17.016 14.9968 16.792 14.7208 16.792H13.0415V15.1253Z"
                            fill="currentColor" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_8037_16307">
                        <rect width="32" height="32" fill="currentColor" />
                    </clipPath>
                    <clipPath id="clip1_8037_16307">
                        <rect width="16" height="16" fill="currentColor" transform="translate(8 8.5)" />
                    </clipPath>
                </defs>
            </svg>
        </button>

        <button @click.prevent="handleTwitterShare">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="text-rubyRed flex-shrink-0 hover:bg-rubyRed hover:text-white hover:rounded-full">
                <g clip-path="url(#clip0_8037_16308)">
                    <circle cx="16" cy="16" r="15.5" stroke="currentColor" />
                    <g clip-path="url(#clip1_8037_16308)">
                        <path
                            d="M17.3126 14.7714L23.1379 8H21.7575L16.6994 13.8795L12.6595 8H8L14.1091 16.8909L8 23.9918H9.38049L14.722 17.7828L18.9884 23.9918H23.6479L17.3123 14.7714H17.3126ZM15.4219 16.9692L14.8029 16.0839L9.87789 9.03921H11.9982L15.9728 14.7245L16.5918 15.6098L21.7582 22.9998H19.6378L15.4219 16.9696V16.9692Z"
                            fill="currentColor" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_8037_16308">
                        <rect width="32" height="32" fill="currentColor" />
                    </clipPath>
                    <clipPath id="clip1_8037_16308">
                        <rect width="15.6479" height="16" fill="currentColor" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>
        </button>

        <button @click.prevent="handleTelegramShare">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="text-rubyRed flex-shrink-0 hover:bg-rubyRed hover:text-white hover:rounded-full">
                <g clip-path="url(#clip0_8037_16312)">
                    <circle cx="16" cy="16" r="15.5" stroke="currentColor" />
                    <g clip-path="url(#clip1_8037_16312)">
                        <path
                            d="M23.755 9.71973C23.5598 9.47997 23.2667 9.3479 22.9296 9.3479C22.7478 9.3479 22.5552 9.38596 22.3571 9.461L8.81115 14.5934C8.0909 14.8663 7.99396 15.2772 8.00028 15.4978C8.00656 15.7173 8.12566 16.119 8.85062 16.3509L11.7234 17.4036L13.2374 20.8693C13.3896 21.3486 13.7076 21.6849 14.1135 21.794C14.2071 21.8191 14.3026 21.8315 14.3988 21.8315C14.7285 21.8315 15.066 21.6863 15.3575 21.4126L16.6118 20.2341L19.6724 22.3407C19.948 22.5444 20.2479 22.6519 20.5404 22.6519C21.134 22.6519 21.6074 22.2173 21.7465 21.5449L23.9619 10.83C24.053 10.3897 23.9795 9.99536 23.755 9.71973ZM12.6754 17.241L16.5847 15.1446L13.8602 17.6437C13.7632 17.7327 13.7092 17.8581 13.709 17.9887C13.7088 17.9935 13.7082 17.9982 13.7082 18.003L13.6988 19.5834L12.6754 17.241ZM14.7156 20.7292C14.6866 20.7564 14.6577 20.7803 14.6291 20.801L14.6405 18.8772L15.8215 19.6901L14.7156 20.7292ZM23.0438 10.6402L20.8284 21.355C20.8061 21.463 20.7332 21.7144 20.5403 21.7144C20.4502 21.7144 20.3385 21.668 20.2257 21.5837C20.2208 21.5801 20.2159 21.5766 20.2109 21.5732L16.8368 19.2508C16.8363 19.2505 16.8359 19.2501 16.8354 19.2498L14.9277 17.9367L19.9671 13.3142C20.1413 13.1545 20.1693 12.8901 20.0325 12.6974C19.8957 12.5047 19.6369 12.444 19.4287 12.5556L12.0364 16.5199L9.1636 15.4671C9.16151 15.4663 9.15941 15.4656 9.15729 15.4648L22.6893 10.3377C22.8034 10.2945 22.883 10.2854 22.9296 10.2854C22.9528 10.2854 23.0087 10.288 23.0281 10.3117C23.0528 10.3422 23.084 10.4456 23.0438 10.6402Z"
                            fill="currentColor" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_8037_16312">
                        <rect width="32" height="32" fill="currentColor" />
                    </clipPath>
                    <clipPath id="clip1_8037_16312">
                        <rect width="16" height="16" fill="currentColor" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>
        </button>

        <button v-if="isNativeShareSupported" @click.prevent="handleNativeShare">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="text-rubyRed flex-shrink-0 hover:bg-rubyRed hover:text-white hover:rounded-full">
                <g clip-path="url(#clip0_25913_153583)">
                    <circle cx="16" cy="16" r="15.5" stroke="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M20 9.83203C19.1716 9.83203 18.5 10.5036 18.5 11.332C18.5 12.1605 19.1716 12.832 20 12.832C20.8284 12.832 21.5 12.1605 21.5 11.332C21.5 10.5036 20.8284 9.83203 20 9.83203ZM17.5 11.332C17.5 9.95132 18.6193 8.83203 20 8.83203C21.3807 8.83203 22.5 9.95132 22.5 11.332C22.5 12.7127 21.3807 13.832 20 13.832C18.6193 13.832 17.5 12.7127 17.5 11.332Z"
                        fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12 14.5C11.1716 14.5 10.5 15.1716 10.5 16C10.5 16.8284 11.1716 17.5 12 17.5C12.8284 17.5 13.5 16.8284 13.5 16C13.5 15.1716 12.8284 14.5 12 14.5ZM9.5 16C9.5 14.6193 10.6193 13.5 12 13.5C13.3807 13.5 14.5 14.6193 14.5 16C14.5 17.3807 13.3807 18.5 12 18.5C10.6193 18.5 9.5 17.3807 9.5 16Z"
                        fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M20 19.168C19.1716 19.168 18.5 19.8395 18.5 20.668C18.5 21.4964 19.1716 22.168 20 22.168C20.8284 22.168 21.5 21.4964 21.5 20.668C21.5 19.8395 20.8284 19.168 20 19.168ZM17.5 20.668C17.5 19.2873 18.6193 18.168 20 18.168C21.3807 18.168 22.5 19.2873 22.5 20.668C22.5 22.0487 21.3807 23.168 20 23.168C18.6193 23.168 17.5 22.0487 17.5 20.668Z"
                        fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13.2946 16.7562C13.4337 16.5176 13.7398 16.4369 13.9784 16.5759L18.5317 19.2292C18.7703 19.3683 18.851 19.6744 18.712 19.913C18.5729 20.1516 18.2668 20.2323 18.0282 20.0932L13.4749 17.4399C13.2363 17.3009 13.1556 16.9947 13.2946 16.7562Z"
                        fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.7052 12.0879C18.8443 12.3264 18.7638 12.6326 18.5253 12.7718L13.9787 15.4251C13.7402 15.5643 13.434 15.4838 13.2948 15.2453C13.1556 15.0068 13.2361 14.7006 13.4746 14.5614L18.0213 11.9081C18.2598 11.7689 18.566 11.8494 18.7052 12.0879Z"
                        fill="currentColor" />
                </g>
                <defs>
                    <clipPath id="clip0_25913_153583">
                        <rect width="32" height="32" fill="currentColor" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { Icon } from '@/components/website';
import { useShare } from "@vueuse/core";
import { openCenteredPopup, getFacebookSharerUrl, getTelegramShareUrl, getTwitterShareUrl } from "./share";

export default defineComponent({
    components: { Icon },
    props: {
        theme: {
            type: String,
            default: 'on-light'
        },
        shareTitle: String,
        shareUrl: String
    },
    setup(props) {
        const title = props.shareTitle ?? document.title;
        const url = props.shareUrl ?? window.location.href;

        const { isSupported, share } = useShare({ title, url });

        const handleNativeShare = () => {
            share()
        }

        const handleFacebookShare = () => {
            openCenteredPopup(getFacebookSharerUrl(url), 'Share this on Facebook', 450, 600);
        }

        const handleTwitterShare = () => {
            openCenteredPopup(getTwitterShareUrl(url, title), 'Share this on Twitter', 450, 600);
        }

        const handleTelegramShare = () => {
            openCenteredPopup(getTelegramShareUrl(url, title), 'Share this on Telegram', 450, 600);
        }

        return {
            isNativeShareSupported: isSupported,
            handleNativeShare,
            handleFacebookShare,
            handleTwitterShare,
            handleTelegramShare,
        }
    }
})
</script>

<style lang="css" scoped>
button {
    padding: 0 !important;
    border-left-width: 0 !important;
}
</style>