import {
    computed,
    defineAsyncComponent,
    defineComponent,
    onMounted,
    reactive,
    ref,
    toRef,
    watch,
} from 'vue'
import { BlockModule } from '../common'
import { Input } from '@/components/ui/input'
import { BnmButton } from '@/components/website/button'
import {
    usePageSearchContentTypeTagsAsync,
    usePageSearchSectionsAsync,
    usePagesSearchAsync,
} from '@/datasources'
import { DateLabelWithTooltip } from '@/components/website/dates'
import { Separator } from '@/components/ui/separator'
import { SelectControl } from '@/components/website/forms/select-control'
import type { PAGE_SEARCH_SORTING_MODE } from '@/types'
import Pagination from '@/components/ui/custom-pagination/pagination.vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { VisuallyHidden } from 'radix-vue'
import QueryString from 'qs'
import { updateHistoryRecord } from 'swup'

new BlockModule({
    selector: '.block-search',
    // @ts-ignore
    vueComponent: defineComponent({
        components: {
            SearchInput: Input,
            BnmButton,
            DateLabelWithTooltip,
            Separator,
            SelectControl,
            Pagination,

            Drawer: defineAsyncComponent(() => import('@/components/ui/drawer/Drawer.vue')),
            DrawerClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue'),
            ),
            DrawerContent: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerContent.vue'),
            ),
            DrawerDescription: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerDescription.vue'),
            ),
            DrawerFooter: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerFooter.vue'),
            ),
            DrawerTitle: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerTitle.vue'),
            ),
            DrawerTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue'),
            ),
            VisuallyHidden,
        },
        setup() {
            const url = new URL(window.location.href)

            const breakpoints = useBreakpoints(breakpointsTailwind)
            const lgAndGreater = breakpoints.greater('lg')

            const searchInputRef = ref<HTMLInputElement>()
            const searchResultsElRef = ref<HTMLElement>()

            const filters = reactive({
                pageNumber: parseInt(url.searchParams.get('pageNumber') ?? '1'),
                searchTerm: url.searchParams.get('searchTerm') ?? '',
                sorting: (url.searchParams.get('sorting') ??
                    'Relevance') as PAGE_SEARCH_SORTING_MODE,
                pageSectionKey: url.searchParams.get('pageSectionKey') ?? '',
                pageContentTypeTagKeys: url.searchParams.getAll('pageContentTypeTagKeys'),
            })

            const searchAsync = usePagesSearchAsync(
                toRef(filters, 'pageNumber'),
                toRef(filters, 'searchTerm'),
                toRef(filters, 'pageSectionKey'),
                toRef(filters, 'pageContentTypeTagKeys'),
                toRef(filters, 'sorting'),
            )

            const onSubmit = () => {}

            const onReset = () => {
                filters.searchTerm = ''
            }

            const filterCount = computed(() => {
                let count = 0

                // if (filters.searchTerm) {
                //     count++
                // }

                if (filters.pageSectionKey) {
                    count++
                }

                count += filters.pageContentTypeTagKeys.length

                return count
            })

            const paginationLabel = computed(() => {
                if (!searchAsync.value) return ''

                const { count, currentPageNumber, pageSize } = searchAsync.value

                return `${Math.min(currentPageNumber * pageSize, count)} of ${count}`
            })

            const contentTypeTagsAsync = usePageSearchContentTypeTagsAsync()
            const sectionsAsync = usePageSearchSectionsAsync()

            watch(
                () => searchAsync.value?.currentPageNumber,
                (newVal, oldVal) => {
                    // If this is the first load, dont scroll to top
                    if (!oldVal) return

                    setTimeout(() => {
                        searchResultsElRef.value?.scrollIntoView({
                            block: 'start',
                        })
                    }, 200)
                },
            )

            watch(
                () => [
                    filters.searchTerm,
                    filters.pageContentTypeTagKeys.join(''),
                    filters.pageSectionKey,
                ],
                () => {
                    filters.pageNumber = 1
                },
            )

            watch(filters, () => {
                const query = QueryString.stringify(filters, {
                    indices: false,
                })

                const url = new URL(window.location.href)

                url.search = query

                updateHistoryRecord(url.toString())
            })

            onMounted(() => {
                setTimeout(() => {
                    searchInputRef.value?.focus()
                }, 300)
            })

            const onResetSideFilters = () => {
                filters.pageSectionKey = ''
                filters.pageContentTypeTagKeys = []
            }

            return {
                onSubmit,
                searchAsync,
                filters,
                onReset,
                paginationLabel,
                contentTypeTagsAsync,
                sectionsAsync,
                searchResultsElRef,
                filterCount,
                lgAndGreater,
                searchInputRef,
                origin: window.location.origin,
                onResetSideFilters,
            }
        },
    }),
})
