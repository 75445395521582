import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type { MarketDataGDPAnnual, MarketDataGDPPerCapita, MarketDataGDPQuater } from '@/types'
import axios from 'axios'

export const getMarketDataRealGDPAnnual = async () => {
    const request = axios.get<APIResponse<MarketDataGDPAnnual>>(
        `${ENDPOINTS.MARKET_DATA_V1}/real-gdp/annual`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataRealGDPQuarter = async () => {
    const request = axios.get<APIResponse<MarketDataGDPQuater>>(
        `${ENDPOINTS.MARKET_DATA_V1}/real-gdp/quarter`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataRealGDPPerCapita = async () => {
    const request = axios.get<APIResponse<MarketDataGDPPerCapita>>(
        `${ENDPOINTS.MARKET_DATA_V1}/real-gdp/per-capita`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
