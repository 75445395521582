import type { APIResponse, GenericListingItem, PaginatedData } from '@/types'
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import axios from 'axios'
import { unref, type MaybeRef } from 'vue'

export const useGenericListingItemsAsync = (
    listingId: MaybeRef<string>,
    tagIds: MaybeRef<string[]> = [],
    sorting: MaybeRef<string> = '0',
    pageNumber: MaybeRef<number> = 1,
    pageSize: MaybeRef<number> = 9,
    options?: AsyncComputedOptions,
) => {
    return computedAsync<PaginatedData<GenericListingItem> | undefined>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            const targetListingId = unref(listingId)

            return axios
                .get<APIResponse<PaginatedData<GenericListingItem>>>(
                    `/api/generic-listing/listing/${targetListingId}`,
                    {
                        signal: abortController.signal,
                        params: {
                            tagIds: unref(tagIds).filter((x) => x !== ''),
                            sorting: unref(sorting),
                            pageNumber: unref(pageNumber),
                            pageSize: unref(pageSize),
                        },
                    },
                )
                .then((response) => response.data.data)
        },
        undefined,
        options,
    )
}
