import Swup from 'swup'
import SwupHeadPlugin from '@swup/head-plugin'
import { ref } from 'vue'
import { importScrollTrigger } from './gsap'
import SwupPreloadPlugin from '@swup/preload-plugin'

const st = importScrollTrigger()

const isVisiting = ref(false)

export const swup = new Swup({
    containers: ['#swup'],
    animationSelector: '[class*="page-transition-"]',
    plugins: [
        new SwupHeadPlugin({
            persistAssets: true,
        }),
        new SwupPreloadPlugin(),
    ],
})

swup.hooks.on('visit:start', (e) => {
    isVisiting.value = true

    if (e.scroll.reset) {
        window.scrollTo({ top: 0, behavior: 'instant' })
    }
})

swup.hooks.on('visit:end', (e) => {
    isVisiting.value = false
    st.refresh()

    const anchor = e.to.hash

    if (anchor) {
        const anchorEl = document.querySelector(anchor)

        if (anchorEl) {
            anchorEl.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
    }
})

export const useSwup = () => {
    return {
        isVisiting,
    }
}
