<template>
    <SelectControl :error-message v-bind="props" v-model="value"></SelectControl>
</template>

<script setup lang="ts" generic="Item">
import { useField } from 'vee-validate'
import SelectControl from './SelectControl.vue'
import type { CommonProps } from './types'
import { toRef } from 'vue'

interface Props {
    name: string
    itemText: keyof Item & string
    itemValue: keyof Item & string
}

const props = defineProps<CommonProps & Props>()

const { errorMessage, value } = useField<string>(toRef(props, 'name'))
</script>

<style scoped></style>
