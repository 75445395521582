<template>
    <div :class="cn('flex w-full flex-col', $props.class, errorMessage && 'is-invalid')">
        <Label v-if="label" :class="cn('mb-2', errorMessage && 'text-destructive')" :for="idAttr">
            {{ label }}
        </Label>

        <Popover v-model:open="isOpen">
            <PopoverTrigger as-child :class="cn('')" :disabled>
                <Button
                    role="combobox"
                    :class="
                        cn(
                            'group relative h-14 w-full justify-between rounded-full border border-input bg-background text-inherit hover:bg-background',
                            buttonClass,
                        )
                    "
                    :aria-expanded="isOpen">
                    <div class="flex flex-nowrap items-center gap-2 truncate text-ellipsis">
                        <p v-if="calendarDate" class="font-bold text-primary">
                            {{
                                formatter.custom(toDate(calendarDate), {
                                    month: 'short',
                                    year: 'numeric',
                                })
                            }}
                        </p>

                        <p v-else class="font-normal text-muted-foreground">
                            {{ placeholder }}
                        </p>
                    </div>
                    <ChevronDown
                        class="size-4 shrink-0 text-rubyRed transition group-data-[state=open]:rotate-180 dark:text-foreground" />
                </Button>
            </PopoverTrigger>

            <!-- <p v-if="errorMessage" class="text-sm text-destructive">{{ errorMessage }}</p> -->

            <PopoverContent :class="cn('w-80 p-3', popoverContentClass)" align="start">
                <div class="mb-2 flex items-center justify-between">
                    <span class="text-xs text-muted-foreground">Filter by month</span>
                    <Button
                        v-if="clearable && modelValue"
                        @click="reset"
                        variant="ghost"
                        type="button"
                        size="sm"
                        class="h-auto px-2 py-1 text-xs">
                        Clear
                    </Button>
                </div>

                <div class="grid grid-cols-2 gap-3">
                    <Select v-model="monthValue">
                        <SelectTrigger aria-label="Select month">
                            <SelectValue placeholder="Month" />
                        </SelectTrigger>
                        <SelectContent class="max-h-[200px]">
                            <SelectItem
                                v-for="month in createYear({ dateObj: today(getLocalTimeZone()) })"
                                :key="month.toString()"
                                :value="month.month.toString()">
                                {{ formatter.custom(toDate(month), { month: 'long' }) }}
                            </SelectItem>
                        </SelectContent>
                    </Select>
                    <Select v-model="yearValue">
                        <SelectTrigger aria-label="Select year">
                            <SelectValue placeholder="Year" />
                        </SelectTrigger>
                        <SelectContent class="max-h-[200px]">
                            <SelectItem
                                v-for="yearValue in createYearRange({
                                    start: today(getLocalTimeZone()).subtract({ years: 10 }),
                                    end: today(getLocalTimeZone()).add({ years: 1 }),
                                }).reverse()"
                                :key="yearValue.toString()"
                                :value="yearValue.year.toString()">
                                {{ yearValue.year }}
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </PopoverContent>
        </Popover>
    </div>
</template>

<script setup lang="ts">
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { today, getLocalTimeZone, CalendarDate } from '@internationalized/date'
import { createYear, createYearRange, toDate } from 'radix-vue/date'
import { computed, ref, watch } from 'vue'
import type { CommonProps } from './types'
import { kebabCase } from 'change-case'
import Label from '@/components/ui/label/Label.vue'
import { Button } from '@/components/ui/button'
import { ChevronDown } from 'lucide-vue-next'
import { useDateFormatter } from 'radix-vue'

interface Props {
    popoverContentClass?: string
    buttonClass?: string
}

const props = withDefaults(defineProps<CommonProps & Props>(), {
    items: () => [],
    clearable: true,
})

const isOpen = ref(false)

const formatter = useDateFormatter(Intl.DateTimeFormat().resolvedOptions().locale)

const modelValue = defineModel<CalendarDate>()

const yearValue = ref<string>()

const monthValue = ref<string>()

const calendarDate = computed(() => {
    if (!yearValue.value || !monthValue.value) return

    return new CalendarDate(parseInt(yearValue.value), parseInt(monthValue.value), 1)
})

const idAttr = computed(() =>
    props.id ? props.id : 'monthYearPicker-' + kebabCase(props.name || ''),
)

const reset = () => {
    modelValue.value = undefined
}

watch(
    () => calendarDate.value,
    (newValue) => {
        modelValue.value = newValue
    },
)

watch(
    () => modelValue.value,
    (newValue) => {
        if (!newValue) {
            yearValue.value = undefined
            monthValue.value = undefined
            return
        }
        yearValue.value = newValue?.year.toString()
        monthValue.value = newValue?.month.toString()
    },
    { immediate: true },
)
</script>

<style scoped></style>
