<template>
    <TabsTrigger
        v-bind="forwardedProps"
        class="flex-shrink-0 rounded-full border border-primary px-4 py-3 text-primary transition heading-6 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground">
        <slot></slot>
    </TabsTrigger>
</template>

<script setup lang="ts">
import { TabsTrigger, type TabsTriggerProps, useForwardProps } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<TabsTriggerProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<style scoped></style>
