<template>
    <div
        :class="
            cn(
                'flex h-18 items-center transition-colors lg:h-[5.5rem]',
                isNavTransparent ? 'border-b border-b-background/70' : 'bg-primary',
                isSolidColorNav && 'border-primary bg-primary',
            )
        ">
        <NavigationMenu
            as="nav"
            class="container max-w-none justify-stretch [&>*]:w-full"
            v-model="currentTrigger">
            <NavigationMenuList class="flex gap-3 text-lg text-white lg:text-sm">
                <slot name="brand"></slot>

                <li class="flex-grow"></li>

                <slot name="desktop-links" :navigate :isNavigating></slot>

                <slot name="search"></slot>

                <li class="xl:hidden">
                    <slot
                        name="mobile-trigger-icon"
                        :openNav="() => (isMobileNavOpen = true)"></slot>
                </li>
            </NavigationMenuList>
        </NavigationMenu>

        <NavbarMobile v-model:open="isMobileNavOpen">
            <template #logo="{ closeNav }">
                <slot name="brand" :closeNav></slot>
            </template>

            <template #menu-links="{ onNavLinkClick }">
                <slot name="mobile-links" :onNavLinkClick></slot>
            </template>
        </NavbarMobile>
    </div>
</template>

<script setup lang="ts">
import { NavigationMenu, NavigationMenuList } from '@/components/ui/navigation-menu'
import { NavbarMobile } from './index'
import { ref, type HTMLAttributes } from 'vue'
import { swup, useSwup } from '@/lib/swup'
import { cn } from '@/lib/utils'
import { useNavContext } from '../composables'

interface Props {
    class?: HTMLAttributes['class']
    title?: string
    transparent?: boolean
}

const props = defineProps<Props>()

const { isVisiting: isNavigating } = useSwup()

const navigate = (e: Event) => {
    const target = e.target as HTMLAnchorElement
    if (target.getAttribute('target') === '_blank') return

    e.preventDefault()

    const href = target.getAttribute('href')

    if (!href) return

    swup.navigate(href)
}

const { isSolidColorNav, isNavTransparent } = useNavContext(props.transparent)

const currentTrigger = ref('')

const isMobileNavOpen = ref(false)
</script>

<style scoped></style>
