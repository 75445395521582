<template>
    <svg :class="cn('custom-icon text-current', props.class)" aria-hidden="true">
        <use :href="`#icon-${symbol}`"></use>
    </svg>
</template>

<script lang="ts" setup>
import { cn } from '@/lib/utils'
import type { HTMLAttributes } from 'vue'

interface Props {
    symbol: string
    class?: HTMLAttributes['class']
}

const props = defineProps<Props>()
</script>
