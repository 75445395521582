import axios from 'axios'
import qs from 'qs'

export const configureAxios = () => {
    axios.defaults.paramsSerializer = (params) => {
        return qs.stringify(params, {
            arrayFormat: 'indices',
            indices: true,
        })
    }
}
