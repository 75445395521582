import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import type { EventAudience } from '@/types'
import { getEventAudiences } from '@/api'

export const useEventAudiencesAsync = (options?: AsyncComputedOptions) => {
    return computedAsync<EventAudience[]>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            return getEventAudiences(abortController.signal)
        },
        [],
        options,
    )
}
