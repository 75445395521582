import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import {
    baseLineChartConfig,
    countTo,
    doIfInViewOrDefer,
    trendColorClasses,
    trendIconClasses,
} from './common'
import { getMarketDataExternalSectorOtherInvestments } from '@/api'
import { defineComponent } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'

new BlockModule({
    selector: '.block-market-data-external-sector-other-investments',
    vueComponent: defineComponent({
        components: { ChartShareButtons },
        setup() {},
    }),
    async setupBlock(el, onTeardown, blockContext) {
        const chartData = await getMarketDataExternalSectorOtherInvestments()

        const latestValue = chartData.data[chartData.data.length - 1]
        const beforeLatestValue = chartData.data[chartData.data.length - 2]
        const valueDelta = (latestValue.y || 0) - (beforeLatestValue.y || 0)

        const timestamp = chartData.lastUpdated
        const date = new Date(timestamp)
        const lastUpdatedValue = date
            .toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            })
            .replace(',', '')
        let trend: keyof typeof trendColorClasses & keyof typeof trendIconClasses = 'neutral'

        if (valueDelta > 0) {
            trend = 'positive'
        } else if (valueDelta < 0) {
            trend = 'negative'
        }

        const setupCounters = () => {
            const previousValue = el.querySelector('.previous-value')
            if (previousValue) {
                previousValue.classList.add(trendColorClasses[trend])
            }

            const onCurrentValCountComplete = () => {
                const latestValueEl = el.querySelector('.latest-date-range')
                if (latestValueEl) {
                    latestValueEl.textContent = `(${latestValue.x})`
                    latestValueEl.classList.add('opacity-100')
                }
            }

            const onPrevValCountComplete = () => {
                const previousValueLabel = el.querySelector('.previous-value-label')
                if (previousValueLabel) {
                    previousValueLabel.textContent = `(vs ${beforeLatestValue.x})`
                    previousValueLabel.classList.add('opacity-100')
                }
            }

            const previousValueIcon = el.querySelector('.previous-value-icon')
            if (previousValueIcon) {
                previousValueIcon.classList.add(trendIconClasses[trend])
            }

            const previousValueLabel = el.querySelector('.previous-value-label')
            if (previousValueLabel) {
                previousValueLabel.textContent = `(${latestValue.x})`
            }

            const currentValueEl = el.querySelector('.current-value-counter')
            if (currentValueEl) {
                countTo(
                    latestValue.y || 0,
                    (val) => {
                        // currentValueEl.textContent = val.toString()

                        let displayValue = val.toString()

                        if (Math.abs(val) >= 1e9) {
                            displayValue = (val / 1e9).toFixed(1)
                        } else if (Math.abs(val) >= 1e6) {
                            displayValue = (val / 1e6).toFixed(1)
                        } else {
                            displayValue = val.toFixed(1)
                        }

                        currentValueEl.textContent = displayValue
                    },
                    onCurrentValCountComplete,
                )
            }

            const previousValueEl = el.querySelector('.previous-value-counter')
            if (previousValueEl) {
                countTo(
                    Math.abs(valueDelta),
                    (val) => {
                        // previousValueEl.textContent = val.toString()

                        let displayValue = val.toString()

                        if (Math.abs(val) >= 1e9) {
                            displayValue = (val / 1e9).toFixed(1)
                        } else if (Math.abs(val) >= 1e6) {
                            displayValue = (val / 1e6).toFixed(1)
                        } else {
                            displayValue = val.toFixed(1)
                        }

                        previousValueEl.textContent = displayValue
                    },
                    onPrevValCountComplete,
                )
            }

            const lastUpdatedEl = el.querySelector('.last-updated')
            if (lastUpdatedEl && timestamp) {
                lastUpdatedEl.textContent = `Last updated: ${lastUpdatedValue}`
            }
        }

        const setupChart = async () => {
            const chartEl = el.querySelector('.chart-container')
            if (!chartEl) return

            const Highcharts = await importHighcharts()

            Highcharts.setOptions({
                lang: {
                    numericSymbols: ['K', 'M', 'B', 'T'],
                },
            })

            const chartConfig = {
                ...baseLineChartConfig,
                chart: {
                    ...baseLineChartConfig.chart,
                    renderTo: chartEl as HTMLElement,
                },
                xAxis: {
                    ...baseLineChartConfig.xAxis,
                    categories: chartData.data.map((x) => x.x.toString()),
                },
                exporting: {
                    enabled: false,
                    filename: 'market-data-external-other-investments',
                },
            }
            const chart = Highcharts.chart({
                ...chartConfig,
                series: [
                    {
                        name: chartData.yLabel,
                        type: 'line',
                        color: '#010066',
                        data: chartData.data.map((x) => x.y),
                    },
                ],
                yAxis: {
                    ...baseLineChartConfig.yAxis,
                    labels: {
                        formatter: function (): string {
                            return (this.value as number) / 1e9 + ''
                        },
                    },
                },
            })

            const download = el.querySelector<HTMLButtonElement>('.download-btn')
            if (download) {
                download.addEventListener('click', () => {
                    chart.downloadCSV()
                })
            }

            onTeardown(() => {
                chart.destroy()
            })
        }

        doIfInViewOrDefer(() => {
            setupChart()
            setupCounters()
        }, blockContext)
    },
})
