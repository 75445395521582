<template>
    <div class="indicator-wrapper relative overflow-hidden">
        <Transition
            enter-active-class="transition ease-out duration-700 [backface-visibility:hidden]"
            :enter-from-class="
                direction === 'down' ? 'translate-y-full opacity-0' : '-translate-y-full opacity-0'
            "
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="absolute transition ease-out duration-700 [backface-visibility:hidden]"
            leave-from-class="translate-y-0 opacity-100"
            :leave-to-class="
                direction === 'down' ? '-translate-y-full opacity-0' : 'translate-y-full opacity-0'
            ">
            <p :key="currentIndex">
                {{ currentIndex.toString().padStart(2, '0') }}
            </p>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

interface Props {
    max: number
    currentIndex: number
}

const props = defineProps<Props>()

const direction = ref('down')

watch(
    () => props.currentIndex,
    (newValue, oldValue) => {
        direction.value = newValue > oldValue ? 'down' : 'up'
    },
)
</script>

<style scoped></style>
