import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type {
    MarketDataHeadlineInflationAnnual,
    MarketDataHeadlineInflationQuarter,
    MarketDataHeadlineInflationMonthly,
} from '@/types'
import axios from 'axios'

export const getMarketDataHeadlineInflationAnnual = async () => {
    const request = axios.get<APIResponse<MarketDataHeadlineInflationAnnual>>(
        `${ENDPOINTS.MARKET_DATA_V1}/headline-inflation/annual`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataHeadlineInflationQuarter = async () => {
    const request = axios.get<APIResponse<MarketDataHeadlineInflationQuarter>>(
        `${ENDPOINTS.MARKET_DATA_V1}/headline-inflation/quarter`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataHeadlineInflationMonthly = async () => {
    const request = axios.get<APIResponse<MarketDataHeadlineInflationMonthly>>(
        `${ENDPOINTS.MARKET_DATA_V1}/headline-inflation/monthly`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
