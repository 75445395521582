<template>
    <div class="flex items-center space-x-2">
        <button @click="$emit('download')" type="button" class="download-btn">
            <span class="sr-only">Download Chart data</span>
            <IconDownload class="size-7 hover:cursor-pointer lg:size-8"></IconDownload>
        </button>
        <Popover>
            <PopoverTrigger>
                <IconShare class="size-7 hover:cursor-pointer lg:size-8"></IconShare>
            </PopoverTrigger>
            <PopoverContent>
                <p class="font-bold">Share Link</p>
                <div
                    class="flex items-center justify-between space-x-2 overflow-hidden rounded-md py-2 text-sm">
                    <div
                        class="w-full overflow-hidden text-ellipsis text-nowrap rounded-sm border border-midGrey/30 px-2 py-1 text-midGrey">
                        <span>
                            {{ copyUrl }}
                        </span>
                    </div>
                    <button
                        @click="copyLink"
                        :class="
                            copyState
                                ? 'border border-primary bg-primary text-white'
                                : 'border border-black bg-black text-white hover:border-black/60 hover:border-opacity-60'
                        "
                        class="rounded-sm px-4 py-1 font-medium transition-colors duration-500">
                        <span>{{ copyState ? 'Copied!' : 'Copy' }}</span>
                    </button>
                </div>
            </PopoverContent>
        </Popover>

        <div class="flex items-center gap-2">
            <slot name="link"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { IconDownload, IconShare } from './icons'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { onMounted, ref } from 'vue'

interface Props {
    shareId: {
        type: string
        required: true
    }
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'download'): void
}>()

const copyState = ref(false)
const copyUrl = ref(`${window.location.origin}${window.location.pathname}#${props.shareId}`)

const copyLink = () => {
    if (copyState.value) return
    copyState.value = true
    navigator.clipboard
        .writeText(copyUrl.value)
        .catch(() => {
            copyState.value = false
        })
        .finally(() => {
            setTimeout(() => {
                copyState.value = false
            }, 2000)
        })
}

const urlHash = window.location.hash.substring(1)
const anchorEl = document.getElementById(urlHash)
const anchorElParent = anchorEl?.closest('.umb-block-grid__layout-container')

const scrollToElementContainer = () => {
    if (!anchorElParent) return
    anchorElParent.classList.add('scroll-margin-top-navbar-offset')
    anchorElParent.scrollIntoView({ behavior: 'smooth' })
}

onMounted(() => {
    setTimeout(() => {
        scrollToElementContainer()
    }, 500)
})
</script>

<style scoped></style>
