import { updateHistoryRecord } from 'swup'
import { BlockModule } from './common'
import type { EmblaCarouselType } from 'embla-carousel'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const addToggleThumbBtnsActive = (
    emblaApiMain: EmblaCarouselType,
    emblaApiThumb: EmblaCarouselType,
): (() => void) => {
    const slidesThumbs = emblaApiThumb.slideNodes()

    const toggleThumbBtnsState = (): void => {
        emblaApiThumb.scrollTo(emblaApiMain.selectedScrollSnap())
        const previous = emblaApiMain.previousScrollSnap()
        const selected = emblaApiMain.selectedScrollSnap()
        slidesThumbs[previous].classList.remove('embla-thumbs__slide--selected')
        slidesThumbs[previous].setAttribute('data-state', 'inactive')
        slidesThumbs[selected].classList.add('embla-thumbs__slide--selected')
        slidesThumbs[selected].setAttribute('data-state', 'active')
    }

    emblaApiMain.on('select', toggleThumbBtnsState)
    emblaApiThumb.on('init', toggleThumbBtnsState)

    return (): void => {
        const selected = emblaApiMain.selectedScrollSnap()
        slidesThumbs[selected].classList.remove('embla-thumbs__slide--selected')
    }
}

export const addThumbBtnsClickHandlers = (
    emblaApiMain: EmblaCarouselType,
    emblaApiThumb: EmblaCarouselType,
): (() => void) => {
    const slidesThumbs = emblaApiThumb.slideNodes()

    const scrollToIndex = slidesThumbs.map((_, index) => {
        return (e: Event): void => {
            e.preventDefault()
            const target = e.target as HTMLElement
            const hash = target.getAttribute('href')
            if (hash) {
                const url = new URL(window.location.href)
                url.hash = hash
                updateHistoryRecord(url.toString())
            }
            emblaApiMain.scrollTo(index)
        }
    })

    slidesThumbs.forEach((slideNode, index) => {
        slideNode.addEventListener('click', scrollToIndex[index], false)
    })

    return (): void => {
        slidesThumbs.forEach((slideNode, index) => {
            slideNode.removeEventListener('click', scrollToIndex[index], false)
        })
    }
}

const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndGreater = breakpoints.greater('lg')

new BlockModule({
    selector: '.block-category-tabs',
    async setupBlock(el, onTeardown) {
        const scrollToElement = () => {
            const categoryTabs = document.querySelector('.block-category-tabs') as HTMLElement
            if (!categoryTabs) return
            const categoryTabsPos = categoryTabs.getBoundingClientRect().top

            if (!lgAndGreater.value ? categoryTabsPos >= 300 : categoryTabsPos >= 400) {
                const offset =
                    (window.scrollY || window.pageYOffset) +
                    (categoryTabs.getBoundingClientRect
                        ? categoryTabs.getBoundingClientRect().top
                        : categoryTabs.offsetTop)
                setTimeout(() => window.scroll(0, offset), 200)
            } else {
                setTimeout(
                    () => categoryTabs.scrollIntoView({ behavior: 'smooth', block: 'start' }),
                    200,
                )
            }
        }

        if (window.location.hash) {
            scrollToElement()
        }

        const emblaEl = el.querySelector('.embla--main') as HTMLElement

        if (!emblaEl) return

        const [{ default: EmblaCarousel }, { default: EmblaAutoHeight }] = await Promise.all([
            import('embla-carousel'),
            import('embla-carousel-auto-height'),
        ])

        const emblaApi = EmblaCarousel(emblaEl, { loop: true }, [
            EmblaAutoHeight({
                active: true,
                breakpoints: {
                    '(min-width: 768px)': {
                        active: false,
                    },
                },
            }),
        ])

        onTeardown(() => emblaApi.destroy())

        const emblaThumbsEl = el.querySelector('.embla--thumbs') as HTMLElement
        if (!emblaThumbsEl) return

        const emblaThumbsApi = EmblaCarousel(emblaThumbsEl)

        const cleanupThumbsActive = addToggleThumbBtnsActive(emblaApi, emblaThumbsApi)
        const cleanupThumbsClick = addThumbBtnsClickHandlers(emblaApi, emblaThumbsApi)

        emblaApi.on('destroy', cleanupThumbsActive).on('destroy', cleanupThumbsClick)
        emblaThumbsApi.on('destroy', cleanupThumbsActive).on('destroy', cleanupThumbsClick)
        emblaThumbsApi.slideNodes().forEach((thumbEl) => {
            thumbEl.addEventListener('click', () => {
                // el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                scrollToElement()
            })
        })

        onTeardown(() => emblaThumbsApi.destroy())

        const winHash = window.location.hash
        if (!winHash) return

        const hashEl = emblaEl.querySelector(`[data-id="${winHash.slice(1)}"]`) as HTMLElement
        if (!hashEl) return
        if (!hashEl.parentElement?.children) return

        const childSlides = Array.from(hashEl.parentElement?.children)
        const hashIndex = childSlides.indexOf(hashEl)

        emblaApi.scrollTo(hashIndex, true)
        emblaThumbsApi.scrollTo(hashIndex, true)
    },
})
