import { computedAsync, useDark, type AsyncComputedOptions } from '@vueuse/core'
import { unref, type MaybeRef } from 'vue'
import axios from 'axios'
import type { APIResponse, PaginatedData } from '@/types'
import type { EventsListingItem } from '@/types'
import { ENDPOINTS } from '@/endpoints'
import { startOfDay } from 'date-fns'

export const createEventsListingComputedAsync = (
    startDate?: MaybeRef<string | undefined>,
    endDate?: MaybeRef<string | undefined>,
    organiser?: MaybeRef<string[]>,
    type?: MaybeRef<string[]>,
    platform?: MaybeRef<string[]>,
    audience?: MaybeRef<string[]>,
    sortMode: MaybeRef<string | undefined> = '1',
    pageNumber: MaybeRef<number> = 1,
    pageSize: MaybeRef<number> = 10,
    options?: AsyncComputedOptions,
) => {
    return computedAsync<PaginatedData<EventsListingItem> | undefined>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            const url = new URL(window.location.origin + ENDPOINTS.EVENTS_V1 + '/search')

            url.searchParams.set('sortMode', unref(sortMode) ?? '1')
            url.searchParams.set('pageNumber', unref(pageNumber).toString())
            url.searchParams.set('pageSize', unref(pageSize).toString())

            const theStartDate = unref(startDate)
            if (theStartDate) {
                url.searchParams.set('startDate', theStartDate)
            }

            const theEndDate = unref(endDate)
            if (theEndDate) {
                url.searchParams.set('endDate', theEndDate)
            }

            const theOrganisers = unref(organiser)
            if (theOrganisers && theOrganisers.length > 0) {
                theOrganisers.forEach((id, index) => {
                    url.searchParams.set(`organiser[${index}]`, id)
                })
            }

            const theTypes = unref(type)
            if (theTypes && theTypes.length > 0) {
                theTypes.forEach((id, index) => {
                    url.searchParams.set(`type[${index}]`, id)
                })
            }

            const thePlatforms = unref(platform)
            if (thePlatforms && thePlatforms.length > 0) {
                thePlatforms.forEach((id, index) => {
                    url.searchParams.set(`platform[${index}]`, id)
                })
            }

            const theAudiences = unref(audience)
            if (theAudiences && theAudiences.length > 0) {
                theAudiences.forEach((id, index) => {
                    url.searchParams.set(`audience[${index}]`, id)
                })
            }

            return axios
                .post<APIResponse<PaginatedData<EventsListingItem>>>(url.toString(), {
                    signal: abortController.signal,
                })
                .then((response) => response.data.data)
        },
        undefined,
        options,
    )
}
