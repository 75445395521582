import { BlockModule } from '../common'
import { getDashboardBreakdown } from '@/api'
import { defineComponent } from 'vue'
import { computedAsync } from '@vueuse/core'
import { format } from 'date-fns'

new BlockModule({
    selector: '.block-market-snapshot-section',
    vueComponent: defineComponent({
        setup() {
            const dataAsync = computedAsync(async (onCancel) => {
                const abortController = new AbortController()

                onCancel(() => abortController.abort())

                const data = await getDashboardBreakdown()

                return data
            })

            const textColor = (trendType: number) => {
                if (trendType === 0) {
                    return 'text-gray-500'
                } else {
                    return 'text-inherit'
                }
            }

            const iconOrientation = (trendType: number) => {
                if (trendType === 0) {
                    return 'hidden'
                } else if (trendType === 1) {
                    return ''
                } else if (trendType === 2) {
                    return 'rotate-180'
                }
            }

            const formatDate = (isoDate: Date) => {
                if (!isoDate) return
                return format(isoDate, 'd MMM yyyy')
            }

            const formatBreakdownValue = (amount: number) => {
                const displayValue = amount.toFixed(1)

                return displayValue + ' billion'
            }

            return {
                dataAsync,
                textColor,
                iconOrientation,
                formatDate,
                formatBreakdownValue,
            }
        },
    }),
})
