import './assets/fonts.css'
import './assets/page-transitions.css'
import './assets/index.css'

import { configureAxios } from './lib/axios'
// import { createSentry } from './lib/sentry'

configureAxios()

// createSentry()

// Page Transitions
import './lib/swup'
import './lib/lazyload'

import './modules/website'
