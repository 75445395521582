import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import { doIfInViewOrDefer, chartLineColors } from './common'
import { getMarketDataGovernmentSecurityShortTermBills } from '@/api'
import { defineComponent } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'
import type { SeriesLineOptions } from 'highcharts'

new BlockModule({
    selector: '.block-market-data-government-security-treasury-bills',
    vueComponent: defineComponent({
        components: { ChartShareButtons },
        setup() {},
    }),
    async setupBlock(el, onTeardown, blockContext) {
        const chartData = await getMarketDataGovernmentSecurityShortTermBills()
        const treasuryBillsData = chartData.malaysiaTreasuryBills

        const timestamp = chartData.lastUpdated
        const date = new Date(timestamp)
        const lastUpdatedValue = date
            .toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            })
            .replace(',', '')

        const setupCounters = () => {
            const lastUpdatedEl = el.querySelector('.last-updated')
            if (lastUpdatedEl && timestamp) {
                lastUpdatedEl.textContent = `Last updated: ${lastUpdatedValue}`
            }
        }

        const setupChart = async () => {
            const chartEl = el.querySelector('.chart-container')
            if (!chartEl) return

            const Highcharts = await importHighcharts()

            const seriesData = [
                {
                    name: 'Up to 3-mth',
                    data: treasuryBillsData.map((x) => ({
                        x: new Date(x.trading_date).getTime(),
                        y: parseFloat(x.up_to_three_month),
                    })),
                },
                {
                    name: 'abv. 3 to 6-mth',
                    data: treasuryBillsData.map((x) => ({
                        x: new Date(x.trading_date).getTime(),
                        y: parseFloat(x.above_three_to_six_month),
                    })),
                },
                {
                    name: 'abv. 6 to 12-mth',
                    data: treasuryBillsData.map((x) => ({
                        x: new Date(x.trading_date).getTime(),
                        y: parseFloat(x.above_six_to_twelve_month),
                    })),
                },
            ]

            const series: SeriesLineOptions[] = seriesData.map((series, index) => ({
                name: series.name,
                type: 'line',
                color: chartLineColors[index],
                data: series.data,
            }))

            const chart = Highcharts.chart({
                chart: {
                    type: 'line',
                    renderTo: chartEl as HTMLElement,
                    marginTop: 40,
                },
                title: {
                    text: '',
                },
                xAxis: {
                    type: 'datetime',
                    lineColor: '#DBDBDB',
                    allowDecimals: false,
                },

                yAxis: {
                    color: '#DBDBDB',
                    title: {
                        text: '',
                        align: 'high',
                        rotation: 0,
                        reserveSpace: false,
                        textAlign: 'left',
                        y: -20,
                    },
                },
                series,
                exporting: {
                    enabled: false,
                    filename: 'government-security-treasury-bills',
                },
                tooltip: {
                    valueDecimals: 2,
                },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 1024,
                            },
                            chartOptions: {
                                legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                },
                            },
                        },
                    ],
                },
            })

            const download = el.querySelector<HTMLButtonElement>('.download-btn')
            if (download) {
                download.addEventListener('click', () => {
                    chart.downloadCSV()
                })
            }

            onTeardown(() => {
                chart.destroy()
            })
        }

        doIfInViewOrDefer(() => {
            setupChart()
            setupCounters()
        }, blockContext)
    },
})
