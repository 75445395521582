import { defineComponent } from 'vue'
import { BlockModule } from '../common'
import { MainNavigation } from './components'
import { Accordion, AccordionContent, AccordionItem } from '@/components/ui/accordion'
import { cn } from '@/lib/utils'

import {
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'

import { AccordionTrigger } from '@/components/website/accordion'
import { useNavContext } from './composables'
import { useBrowserLocation } from '@vueuse/core'

new BlockModule({
    selector: '.bnm-main-navigation',
    vueComponent: defineComponent({
        components: {
            MainNavigation,
            RadixNavigationMenuItem: NavigationMenuItem,
            RadixNavigationMenuLink: NavigationMenuLink,
            RadixNavigationMenuTrigger: NavigationMenuTrigger,
            RadixNavigationMenuContent: NavigationMenuContent,
            // RadixNavigationMenuViewport,
            // RadixNavigationMenuRoot,
            Accordion,
            AccordionContent,
            AccordionTrigger,
            AccordionItem,
        },
        setup() {
            const { isSolidColorNav, isNavTransparent, activeNavLinkIds, isNavHidden } =
                useNavContext()

            const location = useBrowserLocation()

            const isNavActive = (href: string) => {
                const url = new URL(href.startsWith('https') ? href : window.location.origin + href)

                return location.value.pathname?.includes(url.pathname) ?? false
            }

            const isNavLinkActive = (key: string) => {
                return activeNavLinkIds.value.includes(key)
            }

            return {
                isSolidColorNav,
                isNavTransparent,
                cn,
                isNavActive,
                isNavLinkActive,
                isNavHidden,
            }
        },
    }),
})
