import { getPageSearchContentTypeTags, getPageSearchSections, getPagesSearch } from '@/api'
import type { PAGE_SEARCH_SORTING_MODE } from '@/types'
import { computedAsync } from '@vueuse/core'
import { unref, type MaybeRef } from 'vue'

export const usePagesSearchAsync = (
    pageNumber: MaybeRef<number>,
    searchTerm?: MaybeRef<string>,
    pageSectionKey?: MaybeRef<string>,
    pageContentTypeTagKeys?: MaybeRef<string[]>,
    sorting?: MaybeRef<PAGE_SEARCH_SORTING_MODE>,
) =>
    computedAsync(async (onCancel) => {
        const abortController = new AbortController()

        onCancel(() => abortController.abort())

        const data = await getPagesSearch(
            unref(pageNumber),
            12,
            unref(searchTerm),
            unref(pageSectionKey),
            unref(pageContentTypeTagKeys),
            unref(sorting),
            abortController.signal,
        )

        return data
    })

export const usePageSearchContentTypeTagsAsync = () =>
    computedAsync(async (onCancel) => {
        const abortController = new AbortController()

        onCancel(() => abortController.abort())

        const data = await getPageSearchContentTypeTags(abortController.signal)

        return data
    })

export const usePageSearchSectionsAsync = () =>
    computedAsync(async (onCancel) => {
        const abortController = new AbortController()

        onCancel(() => abortController.abort())

        const data = await getPageSearchSections(abortController.signal)

        return data
    })
