import { defineAsyncComponent, defineComponent } from 'vue'
import { BlockModule } from '../common'

new BlockModule({
    selector: '.block-social-sharing',
    vueComponent: defineComponent({
        components: {
            ShareIcons: defineAsyncComponent(
                () => import('@/components/website/share-socials/share-icons.vue'),
            ),
        },
        setup() {},
    }),
})
