import { defineComponent } from 'vue'
import { BlockModule } from '../common'
import { TabsContent, TabsTrigger, Tabs, TabsList } from '@/components/ui/tabs'
import { TopicTabs } from '@/components/website'
import { ShareIcons } from '@/components/website/share-socials'

new BlockModule({
    selector: '.block-bnm-topics',
    vueComponent: defineComponent({
        components: {
            TopicTabs,
            Tabs,
            TabsList,
            TabsContent,
            TabsTrigger,
            ShareIcons,
        },
        setup() {},
    }),
})
