import { createApp, defineAsyncComponent, defineComponent, ref } from 'vue'
import { BlockModule } from '../common'
import './button.css'
import { useEventListener } from '@vueuse/core'
import { importGsap } from '@/lib/gsap'

// const gsap = importGsap()

new BlockModule({
    selector: '.block-button',
    setupBlock(el, onTeardown) {
        const button = el.querySelector<HTMLElement>('.block-button__button')
        if (!button) return

        // const blob = button.querySelector('.blob')
        // if (blob) {
        //     const tl = gsap.timeline({
        //         repeat: -1,
        //     })

        //     tl.to(blob, {
        //         rotate: '360deg',
        //         duration: 6,
        //         ease: 'power4.out',
        //     })

        //     el.addEventListener('mouseover', () => {
        //         tl.timeScale(4)
        //     })

        //     el.addEventListener('mouseout', () => {
        //         tl.timeScale(1)
        //     })
        // }
        // if (blobs.length > 0) {
        //     const context = gsap.context(() => {
        //         blobs.forEach((el, idx) => {
        //             const timeline = gsap.timeline({
        //                 repeat: -1,
        //                 paused: true,
        //             })
        //             timeline
        //                 .fromTo(
        //                     el,
        //                     {
        //                         x: '-50%',
        //                         y: '-80%',
        //                     },
        //                     {
        //                         x: '100%',
        //                         y: '-80%',
        //                         ease: 'none',
        //                         duration: 2,
        //                     },
        //                 )
        //                 .fromTo(
        //                     el,
        //                     {
        //                         x: '100%',
        //                         y: '-80%',
        //                     },
        //                     {
        //                         x: '100%',
        //                         y: '25%',
        //                         ease: 'none',
        //                         duration: 0.5,
        //                     },
        //                 )
        //                 .fromTo(
        //                     el,
        //                     {
        //                         x: '100%',
        //                         y: '25%',
        //                     },
        //                     {
        //                         x: '-50%',
        //                         y: '25%',
        //                         ease: 'none',
        //                         duration: 2,
        //                     },
        //                 )
        //                 .fromTo(
        //                     el,
        //                     {
        //                         x: '-50%',
        //                         y: '25%',
        //                     },
        //                     {
        //                         x: '-50%',
        //                         y: '-80%',
        //                         ease: 'none',
        //                         duration: 1,
        //                     },
        //                 )
        //             timeline.progress(idx * 0.33).play()
        //         })
        //     })
        // }

        // We don't want the share dialog to take over the entire button as
        // it may cause the buttton to flicker and shift.
        // Hence, we only take over the element that is supposed to house the dialog.
        const shareDialogAppEl = el.querySelector('.share-dialog-app')
        if (!shareDialogAppEl) return

        const shareDialogVueApp = createApp(
            defineComponent({
                components: {
                    ButtonShareDialog: defineAsyncComponent(
                        () => import('./components/ButtonShareDialog.vue'),
                    ),
                },
                setup() {
                    const showShareDialog = ref(false)

                    useEventListener(button, 'click', () => {
                        showShareDialog.value = true
                    })

                    return {
                        showShareDialog,
                    }
                },
            }),
        )

        shareDialogVueApp.mount(shareDialogAppEl)

        onTeardown(() => {
            shareDialogVueApp.unmount()
        })
    },
})
