<script setup lang="ts">
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import { TabsRoot, useForwardPropsEmits, TabsList } from 'radix-vue'
import type { TabsRootEmits, TabsRootProps } from 'radix-vue'

interface Props {}

const props = defineProps<TabsRootProps & Props>()
const emits = defineEmits<TabsRootEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
    <TabsRoot v-bind="forwarded">
        <ScrollArea class="mb-5 pb-3">
            <TabsList class="flex gap-3 ps-5 xl:ps-0">
                <slot name="tab-triggers"></slot>
            </TabsList>
            <ScrollBar orientation="horizontal" />
        </ScrollArea>

        <slot />
    </TabsRoot>
</template>
