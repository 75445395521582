import { useIntersectionObserver } from '@vueuse/core'
import { onBeforeUnmount, ref, unref } from 'vue'

export function useIsStuck() {
    const stickyElRef = ref<HTMLElement | null>()
    const isStuck = ref(false)

    const ioEl = document.createElement('div')
    ioEl.style.height = '1px'

    const { stop, resume } = useIntersectionObserver(
        ioEl,
        (entries) => {
            const intersectionRatio = entries[0].intersectionRatio
            if (intersectionRatio === 0) {
                // When the user is at the top of the page and the sticky element
                // has not scrolled into view yet, isStuck will be set to `true`.
                // To mitigate this, we only consider it is stuck if the y is a negative number
                isStuck.value = true && entries[0].boundingClientRect.y < 0
            } else if (intersectionRatio === 1) {
                isStuck.value = false
            }
        },
        { threshold: [0, 1], immediate: false },
    )

    const observe = () => {
        const el = unref(stickyElRef)
        if (!el) return
        el.parentElement?.insertBefore(ioEl, el)
        resume()
    }

    onBeforeUnmount(() => {
        ioEl.remove()
        stop()
    })

    return {
        isStuck,
        stickyElRef,
        start: () => observe(),
        stop: () => stop(),
    }
}
