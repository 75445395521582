import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type { EventPlatform } from '@/types'
import type { GenericAbortSignal } from 'axios'
import axios from 'axios'

export const getEventPlatforms = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<EventPlatform[]>>(`${ENDPOINTS.EVENTS_V1}/platforms`, {
        signal,

        // When a request is cancelled, we want to suppress the toast
        suppressToast: !!signal,
    })

    const response = await useErrorHandling(request)

    return response.data.data
}
