import { defineComponent } from 'vue'
import { BlockModule } from './common'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

new BlockModule({
    selector: '.block-tabs',
    vueComponent: defineComponent({
        components: { Tabs, TabsContent, TabsList, TabsTrigger },
        setup() {
            console.log('Tabs init')
        },
    }),
})
