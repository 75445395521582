import { defineComponent } from 'vue'
import { BlockModule } from '../common'
import { Accordion, AccordionContent, AccordionItem } from '@/components/ui/accordion'
import { AccordionTrigger } from '@/components/website/accordion'

new BlockModule({
    selector: '.block-accordion',
    vueComponent: defineComponent({
        components: { Accordion, AccordionContent, AccordionItem, AccordionTrigger },
        setup() {
            console.log('Accordion init')
        },
    }),
})
