<script setup lang="ts">
import { getLocalTimeZone, type CalendarDate } from '@internationalized/date'
import { createEventsListingComputedAsync } from './datasource'
import { ref, toRef, watch } from 'vue'
import { computed } from 'vue'
import { endOfMonth } from 'date-fns'
import { Pagination } from '@/components/ui/custom-pagination'
import EventListingItem from './EventListingItem.vue'

interface Props {
    filterOrganiserIds?: string[]
    filterTypeIds?: string[]
    filterPlatformIds?: string[]
    filterAudienceIds?: string[]
    filterSortMode?: string
    filterCount?: number
}

const props = withDefaults(defineProps<Props>(), {
    filterOrganiserIds: () => [],
    filterTypeIds: () => [],
    filterPlatformIds: () => [],
    filterAudienceIds: () => [],
    filterCount: 0,
})

const filterMonthYearModel = defineModel<CalendarDate>('monthYearCalendarDate')

const isBusy = ref(false)

const pageNumber = ref(1)

const eventsListingAsync = createEventsListingComputedAsync(
    computed(() => {
        if (!filterMonthYearModel.value) return

        return filterMonthYearModel.value.toDate(getLocalTimeZone()).toISOString()
    }),
    computed(() => {
        if (!filterMonthYearModel.value) return

        return endOfMonth(filterMonthYearModel.value.toDate(getLocalTimeZone())).toISOString()
    }),
    toRef(props, 'filterOrganiserIds'),
    toRef(props, 'filterTypeIds'),
    toRef(props, 'filterPlatformIds'),
    toRef(props, 'filterAudienceIds'),
    toRef(props, 'filterSortMode'),
    pageNumber,
    10,
    { evaluating: isBusy },
)

const rootEl = ref<HTMLElement>()

watch(
    () => eventsListingAsync.value?.currentPageNumber,
    (newVal, oldVal) => {
        // This means it is the 1st load. We dont want to scroll up during 1st load
        if (!oldVal) return

        setTimeout(() => {
            rootEl.value?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }, 200)
    },
)

watch(
    () =>
        [
            props.filterAudienceIds.length,
            props.filterOrganiserIds.length,
            props.filterPlatformIds.length,
            props.filterTypeIds.length,
        ].join(','),
    () => {
        pageNumber.value = 1
    },
)
</script>

<template>
    <div ref="rootEl" class="scroll-margin-top-navbar-offset flex flex-col gap-8">
        <div class="flex flex-col gap-4 lg:flex-row lg:items-end lg:gap-8">
            <div class="flex-shrink-0 lg:w-[47%]">
                <h2 class="heading-2">All Events</h2>
            </div>

            <div class="hidden flex-grow flex-col gap-4 lg:grid lg:grid-cols-12 lg:items-center">
                <div class="lg:col-span-4 font-bold body-2">Organiser</div>

                <div class="lg:col-span-3 font-bold body-2">Type</div>

                <div class="lg:col-span-3 font-bold body-2">Platform</div>

                <div class="lg:col-span-2 font-bold body-2">Audience</div>
            </div>

            <div class="hidden w-8 lg:block"></div>
        </div>

        <template v-if="eventsListingAsync && eventsListingAsync.items.length > 0">
            <div class="grid divide-y divide-lightGrey md:grid-cols-2 md:gap-x-9 lg:grid-cols-1">
                <EventListingItem
                    v-for="item in eventsListingAsync.items"
                    :key="item.id"
                    :item
                    class="first:border-t">
                </EventListingItem>
            </div>

            <Pagination
                v-if="eventsListingAsync"
                v-model="pageNumber"
                :page-size="eventsListingAsync.pageSize"
                :total-pages="eventsListingAsync.totalPages">
            </Pagination>
        </template>

        <div v-if="eventsListingAsync?.count === 0" class="rounded-2xl border p-4 lg:p-10">
            <p class="text-center text-lightGrey heading-4">
                <span v-if="filterCount > 0">
                    Sorry, we cannot find any events matching your filters
                </span>
                <span v-else> Sorry, there's no events </span>
            </p>
        </div>
    </div>
</template>

<style scoped></style>
