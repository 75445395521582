import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import type { EventOrganizer } from '@/types'
import { getEventOrganizers } from '@/api'

export const useEventOrganizersAsync = (options?: AsyncComputedOptions) => {
    return computedAsync<EventOrganizer[]>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            return getEventOrganizers(abortController.signal)
        },
        [],
        options,
    )
}
