import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import {
    countTo,
    doIfInViewOrDefer,
    chartLineColors,
    baseLineChartConfig,
    trendColorClasses,
    trendIconClasses,
    setupChartCounters,
} from './common'
import {
    getMarketDataExternalSectorPortfolioInvestments,
    getMarketDataForeignExInterbankSwap,
} from '@/api'
import { defineComponent } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'

new BlockModule({
    selector: '.block-market-data-external-sector-portfolio-investments',
    vueComponent: defineComponent({
        components: { ChartShareButtons },
        setup() {},
    }),
    async setupBlock(el, onTeardown, blockContext) {
        const chartData = await getMarketDataExternalSectorPortfolioInvestments()

        const latestValue = chartData[0].data[chartData[0].data.length - 1]
        const beforeLatestValue = chartData[0].data[chartData[0].data.length - 2]
        const valueDelta = (latestValue.y || 0) - (beforeLatestValue.y || 0)

        const timestamp = chartData[0].lastUpdated
        const date = new Date(timestamp)
        const lastUpdatedValue = date
            .toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            })
            .replace(',', '')
        let trend: keyof typeof trendColorClasses & keyof typeof trendIconClasses = 'neutral'

        if (valueDelta > 0) {
            trend = 'positive'
        } else if (valueDelta < 0) {
            trend = 'negative'
        }

        const setupChart = async () => {
            const chartEl = el.querySelector('.chart-container')
            if (!chartEl) return

            const Highcharts = await importHighcharts()

            Highcharts.setOptions({
                lang: {
                    numericSymbols: ['K', 'M', 'B', 'T'],
                },
            })

            const chart = Highcharts.chart({
                ...baseLineChartConfig,
                chart: {
                    ...baseLineChartConfig.chart,
                    renderTo: chartEl as HTMLElement,
                },
                xAxis: chartData.map((x) => ({
                    categories: x.data.map((x) => x.x.toString()),
                    lineColor: '#DBDBDB',
                    allowDecimals: false,
                })),
                exporting: {
                    enabled: false,
                    filename: 'external-sector-portfolio-investments',
                },
                series: chartData.map((x, index) => ({
                    name: x.category,
                    type: 'line',
                    color: chartLineColors[index],
                    data: x.data.map((x) => x.y),
                })),
                yAxis: {
                    ...baseLineChartConfig.yAxis,
                    labels: {
                        formatter: function (): string {
                            return (this.value as number) / 1e9 + ''
                        },
                    },
                },
            })

            const download = el.querySelector<HTMLButtonElement>('.download-btn')
            if (download) {
                download.addEventListener('click', () => {
                    chart.downloadCSV()
                })
            }

            onTeardown(() => {
                chart.destroy()
            })
        }

        doIfInViewOrDefer(() => {
            setupChart()
            setupChartCounters(
                el,
                trend,
                latestValue,
                beforeLatestValue,
                valueDelta,
                timestamp,
                lastUpdatedValue,
            )
        }, blockContext)
    },
})
