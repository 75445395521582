import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import type { EventType } from '@/types'
import { getEventTypes } from '@/api'

export const useEventTypesAsync = (options?: AsyncComputedOptions) => {
    return computedAsync<EventType[]>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            return getEventTypes(abortController.signal)
        },
        [],
        options,
    )
}
