import { defineComponent } from 'vue'
import { BlockModule } from './common'
import { EventsListing } from '@/components/website/events'

new BlockModule({
    selector: '.block-events-listing',
    vueComponent: defineComponent({
        components: { EventsListing },
    }),
})
