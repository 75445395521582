export function openCenteredPopup (url: string, windowName: string, width: number, height: number) {
    const leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    const topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    return window.open(
        url,
        windowName,
        "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
        + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY="
        + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"
    );
}

export function getFacebookSharerUrl (url: string) {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`
}

export function getTwitterShareUrl (url: string, text?: string) {
    const shareUrl = new URL(`https://x.com/intent/post`);
    shareUrl.searchParams.set('url', url);
    if (text) {
        shareUrl.searchParams.set('text', text);
    }
    return shareUrl.toString()
}

export function getTelegramShareUrl(url: string, text?: string) {
    const shareUrl = new URL('https://telegram.me/share/url');
    shareUrl.searchParams.set('url', url);
    if (text) {
        shareUrl.searchParams.set('text', text);
    }
    return shareUrl.toString();
}
