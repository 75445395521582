<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
    NavigationMenuViewport,
    type NavigationMenuViewportProps,
    useForwardProps,
} from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps<NavigationMenuViewportProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
    <div class="absolute left-0 top-full flex justify-center">
        <NavigationMenuViewport
            v-bind="forwardedProps"
            :class="
                cn(
                    'origin-top-center relative mt-1.5 h-[--radix-navigation-menu-viewport-height] w-full overflow-hidden rounded-5xl border border-gray-200 bg-white text-gray-950 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50 md:w-[--radix-navigation-menu-viewport-width]',
                    props.class,
                )
            " />
    </div>
</template>
