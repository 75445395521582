import './index.css'
import { BlockModule } from '../common'
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue'

interface TradeOffice {
    name: string
    lat: number
    lng: number
}

interface TradeOfficeMarkerRecord {
    tradeOffice: TradeOffice
    marker: L.Marker
    isActive: boolean
}

new BlockModule({
    selector: '.block-trade-offices-map',
    vueComponent: defineComponent({
        props: {
            tradeOfficesJson: String,
        },
        components: {
            LeafletMaps: defineAsyncComponent(
                () => import('@/components/website/leaflet-maps/leaflet-maps.vue'),
            ),
        },
        setup(props) {
            const anchorRef = ref<HTMLElement>()

            const tradeOffices = computed<TradeOffice[]>(() => {
                return props.tradeOfficesJson ? JSON.parse(props.tradeOfficesJson) : []
            })

            let lastActiveRecord: TradeOfficeMarkerRecord | null = null
            const markerLayers: TradeOfficeMarkerRecord[] = []

            const onMapReady = async (mapRef: L.Map) => {
                if (!tradeOffices.value) return

                const { default: L } = await import('leaflet')

                const fg = L.featureGroup()

                const circleIcon = L.icon({
                    iconUrl: '/client/assets/vectors/map-marker-circle-icon.svg',
                    iconSize: [48, 63],
                    iconAnchor: [24, 60],
                })

                const circlePinIcon = L.icon({
                    iconUrl: '/client/assets/vectors/map-marker-icon.svg',
                    iconSize: [48, 63],
                    iconAnchor: [24, 60],
                })

                for (const item of tradeOffices.value) {
                    const iconMarker = L.marker([item.lat, item.lng], {
                        icon: circleIcon,
                    })

                    iconMarker.bindTooltip(item.name, {
                        direction: 'top',
                        opacity: 1,
                        offset: [0, -60],
                        interactive: false,
                        permanent: true,
                    })

                    const record = {
                        marker: iconMarker,
                        tradeOffice: item,
                        isActive: false,
                    }

                    markerLayers.push(record)

                    iconMarker.addEventListener('click', (e) => {
                        if (lastActiveRecord && lastActiveRecord !== record) {
                            lastActiveRecord.isActive = false
                            lastActiveRecord.marker.setIcon(circleIcon)
                            lastActiveRecord.marker.closeTooltip()
                        }

                        lastActiveRecord = record

                        record.isActive = !record.isActive

                        iconMarker.setIcon(record.isActive ? circlePinIcon : circleIcon)

                        record.isActive ? iconMarker.openTooltip() : iconMarker.closeTooltip()
                    })

                    fg.addLayer(iconMarker)
                }

                mapRef.addLayer(fg)

                markerLayers.forEach((x) => x.marker.closeTooltip())

                mapRef.addEventListener('click', () => {
                    if (lastActiveRecord) {
                        lastActiveRecord.isActive = false
                        lastActiveRecord.marker.setIcon(circleIcon)
                        lastActiveRecord.marker.closeTooltip()
                        lastActiveRecord = null
                        return
                    }
                })

                mapRef.fitBounds(fg.getBounds(), {
                    paddingBottomRight: [10, 10],
                    paddingTopLeft: [10, 10],
                })
            }

            return {
                onMapReady,
                anchorRef,
            }
        },
    }),
    // async setupBlock(el, onTeardown) {
    //     const mapContainer = el.querySelector('.map-container')
    //     if (!mapContainer) return

    //     const tradeOfficesJson = el.getAttribute('data-trade-offices')
    //     if (!tradeOfficesJson) return

    //     const tradeOffices = JSON.parse(tradeOfficesJson) as TradeOffice[]

    //     import('leaflet/dist/leaflet.css')
    //     import('leaflet-gesture-handling/dist/leaflet-gesture-handling.css')

    //     const { default: L } = await import('leaflet')

    //     window.L = L

    //     const { default: GestureHandling } = await import('leaflet-gesture-handling')

    //     L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

    //     const tileLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //         attribution:
    //             '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //     })

    //     const map = L.map(mapContainer as HTMLElement, {
    //         zoom: 10,
    //         center: [4.2105, 101.9785],
    //         // @ts-ignore
    //         gestureHandling: true,
    //     })

    //     map.addLayer(tileLayer)

    //     const fg = L.featureGroup()

    //     let lastActiveRecord: TradeOfficeMarkerRecord | null = null
    //     const markerLayers: TradeOfficeMarkerRecord[] = []

    //     const circleIcon = L.icon({
    //         iconUrl: '/client/assets/vectors/map-marker-circle-icon.svg',
    //         iconSize: [48, 63],
    //         iconAnchor: [24, 60],
    //     })

    //     const circlePinIcon = L.icon({
    //         iconUrl: '/client/assets/vectors/map-marker-icon.svg',
    //         iconSize: [48, 63],
    //         iconAnchor: [24, 60],
    //     })

    // for (const item of tradeOffices) {
    //     const iconMarker = L.marker([item.lat, item.lng], { icon: circleIcon })

    //     const record = {
    //         marker: iconMarker,
    //         tradeOffice: item,
    //         isActive: false,
    //     }

    //     markerLayers.push(record)

    //     iconMarker.addEventListener('click', () => {
    //         if (lastActiveRecord && lastActiveRecord !== record) {
    //             lastActiveRecord.isActive = false
    //             lastActiveRecord.marker.setIcon(circleIcon)
    //         }

    //         lastActiveRecord = record

    //         record.isActive = !record.isActive

    //         iconMarker.setIcon(record.isActive ? circlePinIcon : circleIcon)
    //     })

    //     fg.addLayer(iconMarker)
    // }

    // map.addLayer(fg)

    // map.addEventListener('click', () => {
    //     if (lastActiveRecord) {
    //         lastActiveRecord.isActive = false
    //         lastActiveRecord.marker.setIcon(circleIcon)
    //         lastActiveRecord = null
    //         return
    //     }
    // })

    // map.fitBounds(fg.getBounds(), {
    //     paddingBottomRight: [10, 10],
    //     paddingTopLeft: [10, 10],
    // })

    //     onTeardown(() => {
    //         map.off()
    //     })
    // },
})
