<template>
    <TooltipProvider v-if="nativeDate">
        <Tooltip>
            <TooltipTrigger as-child>
                <Skeleton v-if="loading" class="inline-block h-4 w-20 align-middle"></Skeleton>
                <span
                    v-else
                    :class="
                        cn(
                            'relative inline-block before:absolute before:bottom-0 before:start-0 before:inline before:h-[1px] before:w-full before:bg-current before:opacity-25 before:transition hover:before:opacity-50 dark:before:opacity-50 dark:hover:before:opacity-75',
                            $props.class,
                        )
                    "
                >
                    {{ getDateString(labelDisplayMode) }}
                </span>
            </TooltipTrigger>
            <TooltipContent>
                <span class="text-xs capitalize">
                    {{ getDateString(tooltipDisplayMode) }}
                </span>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
</template>

<script setup lang="ts">
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { computed, type HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'
import { Skeleton } from '@/components/ui/skeleton'

type DateTimeDisplayMode = 'distanceFromNow' | 'short' | 'long'

interface Props {
    isoDate?: string | null
    class?: HTMLAttributes['class']
    labelDisplayMode?: DateTimeDisplayMode
    tooltipDisplayMode?: DateTimeDisplayMode
    loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    labelDisplayMode: 'long',
    tooltipDisplayMode: 'distanceFromNow',
})

const nativeDate = computed(() => props.isoDate && parseISO(props.isoDate))

const getDateString = (mode: DateTimeDisplayMode) => {
    if (!nativeDate.value) return

    switch (mode) {
        case 'distanceFromNow':
            return formatDistanceToNow(nativeDate.value, { addSuffix: true })
        case 'short':
            return format(nativeDate.value, 'd MMMM yyyy')
        case 'long':
        default:
            return format(nativeDate.value, 'PP p')
    }
}
</script>

<style scoped></style>
