<template>
    <div class="relative flex flex-col gap-4 py-6 lg:flex-row lg:items-center lg:gap-8">
        <div class="flex flex-shrink-0 items-center gap-4 lg:w-[47%] lg:gap-6">
            <EventThumbnail class="flex-shrink-0" :image-src="item?.image.url"></EventThumbnail>

            <div class="flex flex-col">
                <div class="text-sm font-bold">{{ item?.dateRangeLabel }}</div>
                <h3 class="line-clamp-3 flex-shrink-0 text-balance text-primary heading-5">
                    <a
                        :href="item?.ctaLink || item?.url"
                        :target="item?.ctaLinkTarget ?? '_self'"
                        class="after:absolute after:inset-0 after:z-1">
                        {{ item?.title || item?.name }}
                    </a>
                </h3>
            </div>
        </div>

        <div class="flex flex-grow flex-col gap-4 lg:grid lg:grid-cols-12 lg:items-center">
            <div class="grid grid-cols-12 lg:col-span-4">
                <div class="col-span-5 font-bold body-2 lg:hidden">Organiser</div>
                <div
                    class="col-span-5 block overflow-hidden text-ellipsis text-pretty body-2 lg:col-span-12">
                    {{ item?.organizer?.title || item?.organizer?.name }}
                </div>
            </div>
            <div class="grid grid-cols-12 lg:col-span-3">
                <div class="col-span-5 font-bold body-2 lg:hidden">Type</div>
                <div
                    class="col-span-5 block overflow-hidden text-ellipsis text-pretty body-2 lg:col-span-12">
                    {{ item?.type?.title || item?.type?.name }}
                </div>
            </div>
            <div class="grid grid-cols-12 lg:col-span-3">
                <div class="col-span-5 font-bold body-2 lg:hidden">Platform</div>
                <div
                    class="col-span-5 block overflow-hidden text-ellipsis text-nowrap body-2 lg:col-span-12">
                    {{ item?.platform?.title || item?.platform?.name }}
                </div>
            </div>
            <div class="grid grid-cols-12 lg:col-span-2">
                <div class="col-span-5 font-bold body-2 lg:hidden">Audience</div>
                <div
                    class="col-span-5 block overflow-hidden text-ellipsis text-nowrap body-2 lg:col-span-12">
                    {{ item?.audience?.title || item?.audience?.name }}
                </div>
            </div>
        </div>

        <div class="flex justify-end">
            <IconChevronRight class="size-8 text-rubyRed"></IconChevronRight>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { EventsListingItem } from '@/types'
import { IconChevronRight } from '../icons'
import { computed } from 'vue'
import { format, isSameDay, isSameMonth, isSameYear, parseISO } from 'date-fns'
import EventThumbnail from './EventThumbnail.vue'

interface Props {
    item?: EventsListingItem
    loading?: boolean
}

const props = defineProps<Props>()

const isCrossYearEvent = computed(() => {
    if (!props.item?.eventEndDateTime) return false
    return !isSameYear(
        parseISO(props.item.eventStartDateTime),
        parseISO(props.item.eventEndDateTime),
    )
})

const isCrossMonthEvent = computed(() => {
    if (!props.item?.eventEndDateTime) return false

    return !isSameMonth(
        parseISO(props.item.eventStartDateTime),
        parseISO(props.item.eventEndDateTime),
    )
})
</script>

<style scoped></style>
