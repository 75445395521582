import { computed, defineComponent, ref, watch } from 'vue'
import { BlockModule } from '../common'
import {
    Accordion,
    AccordionContent,
    AccordionTrigger,
    AccordionItem,
} from '@/components/ui/accordion'
import { TabsContent } from 'radix-vue'
import { ProductListBlock, ProductListIndexIndicator, ProductListTabTrigger } from './components'
import { FadeTransition } from '@/components/website/transitions'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

new BlockModule({
    selector: '.block-product-list',
    vueComponent: defineComponent({
        components: {
            ProductListBlock,
            ProductListTabTrigger,
            ProductListIndexIndicator,
            TabsContent,
            Accordion,
            AccordionContent,
            AccordionTrigger,
            AccordionItem,
            FadeTransition,
        },
        setup() {
            const activeTab = ref<string>()
            const activePanel = ref<string>()

            const breakpoints = useBreakpoints(breakpointsTailwind)
            const xlAndGreater = breakpoints.greater('xl')

            const activeIndex = computed(() => {
                if (!activePanel.value) return 1

                return parseInt(activePanel.value.split('-').reverse()[0])
            })

            watch(activeTab, () => {
                activePanel.value = undefined
            })

            watch(activePanel, (newValue) => {
                if (!xlAndGreater.value) return

                setTimeout(() => {
                    document
                        .querySelector(`[data-accordion-item-id="${newValue}"]`)
                        ?.scrollIntoView({
                            block: 'center',
                            behavior: 'smooth',
                        })
                }, 300)
            })

            return {
                activePanel,
                activeIndex,
                activeTab,
            }
        },
    }),
})
