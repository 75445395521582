import { swup } from '@/lib/swup'
import { breakpointsTailwind, useBreakpoints, useScroll } from '@vueuse/core'
import { computed, ref, unref, watch, type MaybeRef } from 'vue'

const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndGreater = breakpoints.greater('lg')

const isScrollingUpLazy = ref(false)
const { arrivedState, y } = useScroll(window)

const isNavTransparent = ref(false)

const activeNavLinkIds = ref<string[]>([])

export const useNavContext = (initialIsNavTransparent?: boolean) => {
    if (typeof initialIsNavTransparent !== 'undefined') {
        isNavTransparent.value = unref(initialIsNavTransparent)
    }

    const checkTransparentNav = (el: Element | Document) => {
        const mainNavModeMeta = el.querySelector('meta[name="main-nav-mode"]')

        isNavTransparent.value = mainNavModeMeta?.getAttribute('content') === 'transparent'
    }

    const checkActiveNavLinkIds = (el: Element | Document) => {
        const metaTag = el.querySelector('meta[name="active-nav-links"]')

        activeNavLinkIds.value = metaTag?.getAttribute('content')?.split(',') ?? []
    }

    const isSolidColorNav = computed(() => {
        if (arrivedState.top) {
            return false
        }
        if (!lgAndGreater.value ? y.value <= 300 : y.value <= 150) {
            return false
        }
        return true
    })

    const isNavHidden = computed(() => {
        if (arrivedState.top) {
            return false
        }
        if (isScrollingUpLazy.value) {
            return false
        }
        if (!lgAndGreater.value ? y.value <= 300 : y.value <= 400) {
            return false
        }

        return true
    })

    // The directions in the `useScroll` composable resets when the user stops scrolling
    // We need to keep track of the scroll direction
    watch(y, (newY, oldY) => {
        if (newY < oldY) {
            isScrollingUpLazy.value = true
        }

        if (newY > oldY) {
            isScrollingUpLazy.value = false
        }
    })

    watch(isNavHidden, (newValue) => {
        document.body.style.setProperty('--bnm-nav-hidden-multiplier', newValue ? '0' : '1')
    })

    swup.hooks.on('content:replace', (visit) => {
        if (visit.to.document) {
            checkTransparentNav(visit.to.document)
            checkActiveNavLinkIds(visit.to.document)
        }
    })

    checkActiveNavLinkIds(document)

    document.body.style.setProperty('--bnm-nav-hidden-multiplier', isNavHidden.value ? '0' : '1')

    return {
        isSolidColorNav,
        isNavTransparent,
        activeNavLinkIds,
        isNavHidden,
    }
}
