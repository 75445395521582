<template>
    <div v-if="totalPages > 1" class="flex items-center justify-center gap-4">
        <button
            @click="handlePrev"
            :disabled="disabled || pageNumber <= 1"
            class="size-8 text-rubyRed disabled:text-lightGrey disabled:opacity-50">
            <svg
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="rotate-180">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 17.1562C13.5046 17.1562 17.1562 13.5046 17.1562 9C17.1562 4.49543 13.5046 0.84375 9 0.84375C4.49543 0.84375 0.84375 4.49543 0.84375 9C0.84375 13.5046 4.49543 17.1562 9 17.1562ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                    fill="currentColor" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.52903 11.721C7.40699 11.5989 7.40699 11.4011 7.52903 11.279L9.80806 9L7.52903 6.72097C7.40699 6.59893 7.40699 6.40107 7.52903 6.27903C7.65107 6.15699 7.84893 6.15699 7.97097 6.27903L10.471 8.77903C10.593 8.90107 10.593 9.09893 10.471 9.22097L7.97097 11.721C7.84893 11.843 7.65107 11.843 7.52903 11.721Z"
                    fill="currentColor" />
            </svg>
        </button>

        <div class="flex items-center gap-4 text-primary">
            <NumberField v-model="pageNumber" :min="1" :max="totalPages">
                <NumberFieldContent>
                    <NumberFieldInput
                        class="h-[4rem] w-[3rem] rounded-xl p-0 text-primary heading-5"
                        @blur="handleGoToPage"></NumberFieldInput>
                </NumberFieldContent>
            </NumberField>
            <span class="heading-6">of</span>
            <span class="heading-5">{{ totalPages }}</span>
        </div>

        <button
            @click="handleNext"
            :disabled="disabled || pageNumber >= totalPages"
            class="size-8 text-rubyRed disabled:text-lightGrey disabled:opacity-50">
            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 17.1562C13.5046 17.1562 17.1562 13.5046 17.1562 9C17.1562 4.49543 13.5046 0.84375 9 0.84375C4.49543 0.84375 0.84375 4.49543 0.84375 9C0.84375 13.5046 4.49543 17.1562 9 17.1562ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                    fill="currentColor" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.52903 11.721C7.40699 11.5989 7.40699 11.4011 7.52903 11.279L9.80806 9L7.52903 6.72097C7.40699 6.59893 7.40699 6.40107 7.52903 6.27903C7.65107 6.15699 7.84893 6.15699 7.97097 6.27903L10.471 8.77903C10.593 8.90107 10.593 9.09893 10.471 9.22097L7.97097 11.721C7.84893 11.843 7.65107 11.843 7.52903 11.721Z"
                    fill="currentColor" />
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
import { NumberField, NumberFieldContent, NumberFieldInput } from '../number-field'

interface Props {
    totalPages: number
    pageSize: number
    disabled?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'pageChange', value: number): void
}>()

const pageNumber = defineModel<number>({
    default: 1,
})

const handlePrev = () => {
    if (pageNumber.value > 1) {
        pageNumber.value -= 1
        emit('pageChange', pageNumber.value)
    }
}

const handleGoToPage = () => {
    emit('pageChange', pageNumber.value)
}

const handleNext = () => {
    if (pageNumber.value < props.totalPages) {
        pageNumber.value += 1
        emit('pageChange', pageNumber.value)
    }
}
</script>

<style scoped lang="scss"></style>
