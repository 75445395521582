import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type { MarketDataDashboardBreakdown } from '@/types'
import axios from 'axios'

export const getDashboardBreakdown = async () => {
    const request = axios.get<APIResponse<MarketDataDashboardBreakdown>>(
        `${ENDPOINTS.MARKET_DATA_V1}/dashboard`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
