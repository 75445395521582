<template>
    <DialogRoot v-model:open="isOpen">
        <div :class="props.class">
            <DialogPortal>
                <DialogOverlay
                    class="data-[state=open]:animate-overlayShow fixed inset-0 z-30 bg-white" />

                <DialogContent
                    class="fixed left-0 top-0 z-50 flex h-full w-full flex-col overflow-hidden bg-white data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 xl:hidden">
                    <DialogTitle class="sr-only">Main Navigation Menu</DialogTitle>
                    <DialogDescription class="sr-only">
                        Navigate around the site using the menu
                    </DialogDescription>

                    <div class="relative z-50 h-18 flex-shrink-0 bg-primary lg:h-[5.5rem]">
                        <div class="container flex h-full items-center justify-between">
                            <slot name="logo" :closeNav="onNavLinkClick"></slot>

                            <DialogClose as-child>
                                <button
                                    type="button"
                                    class="flex h-8 w-8 items-center justify-center rounded-full outline outline-1 outline-white">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.7141 13.7851L2.34317 3.41421C2.08295 3.154 2.08295 2.73162 2.34317 2.4714C2.60339 2.21119 3.02576 2.21119 3.28598 2.4714L13.6569 12.8423C13.9171 13.1025 13.9171 13.5249 13.6569 13.7851C13.3967 14.0453 12.9743 14.0453 12.7141 13.7851Z"
                                            fill="white" />
                                        <path
                                            d="M13.6569 3.1005L3.28598 13.4714C3.02576 13.7316 2.60339 13.7316 2.34317 13.4714C2.08295 13.2112 2.08295 12.7888 2.34317 12.5286L12.7141 2.1577C12.9743 1.89748 13.3967 1.89748 13.6569 2.1577C13.9171 2.41791 13.9171 2.84029 13.6569 3.1005Z"
                                            fill="white" />
                                    </svg>
                                </button>
                            </DialogClose>
                        </div>
                    </div>
                    <slot name="menu-links" :onNavLinkClick></slot>
                </DialogContent>
            </DialogPortal>
        </div>
    </DialogRoot>
</template>

<script setup lang="ts">
import {
    DialogClose,
    DialogTitle,
    DialogDescription,
    DialogContent,
    DialogPortal,
    DialogRoot,
    DialogOverlay,
} from 'radix-vue'
import { type HTMLAttributes } from 'vue'

interface Props {
    class?: HTMLAttributes['class']
}

const props = defineProps<Props>()

const isOpen = defineModel<boolean>('open')

const onNavLinkClick = () => {
    isOpen.value = false
}
</script>
