import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type { MarketDataLabourParticipationRate, MarketDataLabourUnemploymentRate, MarketDataLabourMedianWages } from '@/types'
import axios from 'axios'

export const getMarketDataLabourParticipationRate = async () => {
    const request = axios.get<APIResponse<MarketDataLabourParticipationRate>>(
        `${ENDPOINTS.MARKET_DATA_V1}/labour-market/participation-rate`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataLabourUnemploymentRate = async () => {
    const request = axios.get<APIResponse<MarketDataLabourUnemploymentRate>>(
        `${ENDPOINTS.MARKET_DATA_V1}/labour-market/unemployment-rate`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataLabourMedianWages = async () => {
    const request = axios.get<APIResponse<MarketDataLabourMedianWages>>(
        `${ENDPOINTS.MARKET_DATA_V1}/labour-market/median-wages`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
