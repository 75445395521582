import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type {
    APIResponse,
    PAGE_SEARCH_SORTING_MODE,
    PageSearchContentTypeTag,
    PageSearchResultItem,
    PageSearchSection,
    PaginatedData,
} from '@/types'
import axios, { type GenericAbortSignal } from 'axios'

export const getPagesSearch = async (
    pageNumber = 1,
    pageSize = 10,
    searchTerm?: string,
    pageSectionKey?: string,
    pageContentTypeTagKeys?: string[],
    sorting?: PAGE_SEARCH_SORTING_MODE,
    signal?: GenericAbortSignal,
) => {
    const request = axios.get<APIResponse<PaginatedData<PageSearchResultItem>>>(
        `${ENDPOINTS.PAGES_V1}/search`,
        {
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                pageSectionKey,
                pageContentTypeTagKeys,
                sorting,
            },
            signal,

            // We want to suppress any cancellation errors
            suppressToast: !!signal,
        },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getPageSearchContentTypeTags = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<PageSearchContentTypeTag[]>>(
        `${ENDPOINTS.PAGES_V1}/content-type-tags`,
        { signal },
    )
    const response = await useErrorHandling(request)

    return response.data.data
}

export const getPageSearchSections = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<PageSearchSection[]>>(`${ENDPOINTS.PAGES_V1}/sections`, {
        signal,
    })
    const response = await useErrorHandling(request)

    return response.data.data
}
