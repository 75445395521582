import { BlockModule } from '../common'

interface UmbracoFormBlockData {
    submitButtonStyle: string
}

const loadScripts = async () => {
    import('./umbraco-forms.css')

    return Promise.all([import('./submit-buttons'), import('./phone-inputs')])
}

new BlockModule({
    selector: '.block-umbraco-form',
    setupBlock(el, onTeardown, { onInView }) {
        const form = el.querySelector('form')
        if (!form) return

        onInView(async () => {
            const [{ processSubmitButtons }, { processPhoneInputs }] = await loadScripts()

            const blockData = $(el).data() as UmbracoFormBlockData
            const $form = $(form)

            processSubmitButtons($form, blockData.submitButtonStyle)

            await processPhoneInputs($form)

            if ($.validator.unobtrusive) {
                $.validator.unobtrusive.parse(el)
            }

            document.dispatchEvent(new CustomEvent('umbracoForms.init'))
        })
    },
})
