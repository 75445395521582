import { BlockModule } from './common'
import { createApp, defineAsyncComponent, defineComponent, ref } from 'vue'
import { useEventListener } from '@vueuse/core'

new BlockModule({
    selector: '.block-investor-success-stories-section',
    async setupBlock(el, onTeardown) {
        const emblaContainer = el.querySelector('.embla')
        const viewportNode = emblaContainer?.querySelector('.embla__viewport') as HTMLElement
        if (!viewportNode) return

        import('./button/button.css')

        const [
            { default: EmblaCarousel },
            { addDotBtnsAndClickHandlers, setupTweenOpacity, addPrevNextBtnsClickHandlers },
        ] = await Promise.all([import('embla-carousel'), import('./embla')])

        const prevBtn = emblaContainer?.querySelector('.embla__prev')
        const nextBtn = emblaContainer?.querySelector('.embla__next')

        const dotsNode = <HTMLElement>emblaContainer?.querySelector('.embla__dots')
        if (!emblaContainer) return

        const emblaApi = EmblaCarousel(viewportNode, {
            loop: true,
            align: 'start',
            breakpoints: { '(min-width: 768px)': { align: 'center' } },
        })

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(emblaApi, dotsNode)
        const removeTweenOpacity = setupTweenOpacity(emblaApi)
        const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            emblaApi,
            prevBtn as HTMLElement,
            nextBtn as HTMLElement,
        )

        const slides = emblaApi.slideNodes()
        const togglePointerEvents = () => {
            const activeIndex = emblaApi.selectedScrollSnap()

            slides.forEach((slide, index) => {
                if (index !== activeIndex) {
                    slide.classList.add('pointer-events-none')
                } else {
                    slide.classList.remove('pointer-events-none')
                }
            })
        }

        togglePointerEvents()
        emblaApi.on('select', togglePointerEvents)

        const playerDialogEl = el.querySelector('.dialog-vue-app')
        const buttons = el.querySelectorAll('button[data-video-player-id]')

        if (playerDialogEl) {
            const dialogVueApp = createApp(
                defineComponent({
                    components: {
                        DialogPopUp: defineAsyncComponent(
                            () => import('@/components/ui/dialog/Dialog.vue'),
                        ),
                        DialogContent: defineAsyncComponent(
                            () => import('@/components/ui/dialog/DialogContent.vue'),
                        ),
                        DialogDescription: defineAsyncComponent(
                            () => import('@/components/ui/dialog/DialogDescription.vue'),
                        ),
                        DialogHeader: defineAsyncComponent(
                            () => import('@/components/ui/dialog/DialogHeader.vue'),
                        ),
                        DialogTitle: defineAsyncComponent(
                            () => import('@/components/ui/dialog/DialogTitle.vue'),
                        ),
                        DialogClose: defineAsyncComponent(
                            () => import('@/components/ui/dialog/DialogClose.vue'),
                        ),
                    },
                    setup() {
                        const activePlayerId = ref('')

                        buttons.forEach((el) => {
                            const videoPlayerId = el.getAttribute('data-video-player-id')

                            useEventListener(el, 'click', () => {
                                activePlayerId.value = videoPlayerId ?? ''
                            })
                        })

                        const onPlayerDialogModelValueUpdate = (isOpen: boolean) => {
                            if (!isOpen) {
                                activePlayerId.value = ''
                            }
                        }

                        return {
                            activePlayerId,
                            onPlayerDialogModelValueUpdate,
                        }
                    },
                }),
            )

            dialogVueApp.mount(playerDialogEl)

            onTeardown(() => {
                dialogVueApp.unmount()
            })
        }

        onTeardown(() => {
            removePrevNextBtnsClickHandlers()
            removeTweenOpacity()
            removeDotBtnsAndClickHandlers()
            emblaApi.off('select', togglePointerEvents)
            emblaApi.destroy()
        })
    },
})
